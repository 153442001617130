import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { allCities } from "../../../actions/UserActions";

// let names = [
//   { label: "Oliver Hansen" },
//   { label: "Van Henry" },
//   { label: "April Tucker" },
//   { label: "Ralph Hubbard" },
//   { label: "Omar Alexander" },
//   { label: "Carlos Abbott" },
//   { label: "Miriam Wagner" },
//   { label: "Bradley Wilkerson" },
//   { label: "Virginia Andrews" },
//   { label: "Kelly Snyder" },
// ];

export default function PaymentForm(props) {
  const dispatch = useDispatch();
  const [allCity, setallCity] = useState([]);
  const userReducer = useSelector((state) => {
    return state.user;
  });
  const handleChange = (event) => {
    props.setUserInfo2({
      ...props.userInfo2,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(async () => {
    await dispatch(allCities());
  }, []);

  useEffect(async () => {
    if (userReducer && userReducer.city) {
      console.log("city state inside use effect...", userReducer.city.data);
      let result = userReducer.city.data.map((o) => ({
        label: o.name,
      }));
      console.log(result);
      setallCity(result);
    }
  }, [userReducer]);
  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom style={{
          textAlign: "center",
          marginTop: "3.5rem",
          marginBottom: "2.5rem",
        }}>
        Gestisci il tuo calendario secondo le tue esigenze: puoi scegliere
        liberamente di <strong>seguire i tuoi pazienti</strong> in uno{" "}
        <strong>studio</strong> oppure online
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        style={{
          textAlign: "center",
          marginTop: "3.5rem",
          marginBottom: "2.5rem",
        }}
        className="mt-5"
      >
        <strong>Come vorresti effettuare le visite con i tuoi pazienti</strong>
      </Typography>
      <Grid container spacing={3} style={{ justifyContent: "center" }}>
        <Grid
          item
          xs={12}
          md={12}
          style={{ textAlign: "center", marginBottom: "3.5rem" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={props.online}
                onChange={() => {
                  props.setOnline(!props.online);
                }}
                name="online"
                color="primary"
              />
            }
            label="Online"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.clinic}
                onChange={() => {
                  props.setClinic(!props.clinic);
                }}
                name="clinic"
                color="primary"
              />
            }
            label="Clinica Studio"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            id="combo-box-demo"
            value={props.userInfo2.city}
            options={allCity}
            onChange={async (e, value) => {
              // handleChange(e);
              await props.setUserInfo2({
                ...props.userInfo2,
                city: value,
              });

              if (value == null) {
                props.seterrorCity("Città");
              } else {
                props.seterrorCity("");
              }
            }}
            getOptionLabel={(name) => name.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Città di riferimento attività"
                variant="outlined"
              />
            )}
          />
          {/* <TextField
            required
            variant="outlined"
            id="cardName"
            name="city"
            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                props.seterrorCity("Please enter city");
              } else {
                props.seterrorCity("");
              }
            }}
            value={props.userInfo2.city}
            label="Città di riferimento attività"
            fullWidth
          /> */}
          {props.errorCity != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>{props.errorCity}</p>
          ) : null}
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            required
            name="CAP"
            value={props.userInfo2.CAP}
            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                props.seterrorCAP("Inserisci il CAP");
              } else {
                props.seterrorCAP("");
              }
            }}
            variant="outlined"
            id="cardNumber"
            label="CAP"
            fullWidth
            autoComplete="cc-number"
          />
          {props.errorCAP != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>{props.errorCAP}</p>
          ) : null}
        </Grid>
        {props.clinic ? (
          <>
            <Grid item xs={12} md={3}>
              <TextField
                required
                name="address"
                variant="outlined"
                value={props.userInfo2.address}
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value == "") {
                    props.seterrorAddress("Inserisci indirizzo");
                  } else {
                    props.seterrorAddress("");
                  }
                }}
                id="expDate"
                label="Indirizzo attività"
                fullWidth
                autoComplete="cc-exp"
              />
              {props.errorAddress != "" ? (
                <p style={{ color: "red", marginTop: "1px" }}>
                  {props.errorAddress}
                </p>
              ) : null}
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                value={props.userInfo2.name}
                name="name"
                onChange={handleChange}
                id="cvv"
                label="Nome Studio/Clinica"
                fullWidth
                autoComplete="cc-csc"
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
