import axios from "axios";
// import { returnErrors } from "./ErrorActions";
import {
  GET_PATIENT,
  GET_EVENT_RECORDS,
  INSERT_SUCCESS,
  GET_CALENDAR_SUCCESS,
  GET_CLINIC,
} from "./type";
import swal from "sweetalert";

export const insertCalendarData = (values) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/storecalendar`, values, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((res) => {
      console.log("insert API response", res);
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in insert event", "error");
    });
};

export const updateCalendarData = (values) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/updatecalendar`, values, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((res) => {
      //   dispatch({ type: INSERT_SUCCESS, payload: res });
      console.log("update calebdar data");
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in update event", "error");
    });
};

export const getCalendarData = (id, token) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/fetchcalendar?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: GET_CALENDAR_SUCCESS, payload: res });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in get event data", "error");
    });
};

export const getMyPatient = (token) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/fetchpatient`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: GET_PATIENT, payload: res });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong for patient list", "error");
    });
};

export const getMyClinic = (token) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/office`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: GET_CLINIC, payload: res });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong for clinic list", "error");
    });
};

export const deleteCalendarEvent = (id, token) => (dispatch) => {
  let formData = new FormData();
  formData.append("id", id);

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/removecalendar`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log(res.data.token);
      if (res.data.token !== null && res.data.token !== undefined) {
        console.log("token is avilable");
        const options1 = { method: 'GET', headers: { Authorization: `Bearer ${process.env.REACT_APP_DAILY}` } };
        fetch(`https://api.daily.co/v1/meeting-tokens/${res.data.token}`, options1)
          .then(response => response.json())
          .then(response => {
            console.log(response.room_name);
            const options2 = { method: 'DELETE', headers: { Authorization: `Bearer ${process.env.REACT_APP_DAILY}` } };
            fetch(`https://api.daily.co/v1/rooms/${response.room_name}`, options2)
              .then(response => response.json())
              .then(async(response) => {
                console.log("room deleted successfully");
              })
              .catch(err => console.error(err));
          })
          .catch(err => console.error(err));
      } else {
        console.log("token not avilable");
      }
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in delete event", "error");
    });
};

export const getRecordsOfEvent = (eventId) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/eventdetails?id=${eventId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((res) => {
      dispatch({ type: GET_EVENT_RECORDS, payload: res.data });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong for patient list", "error");
    });
};

export const updateRoomlinkForEvent = (values) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/updateroomlink`, values, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((res) => {
      console.log("room Url added successfully");
    })
    .catch((err) => {
      swal("Oops!", "Something wrong for patient list", "error");
    });
};

export const insertRecordsOfEvents = (values) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/storecalendar`, values, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((res) => {
      console.log("insert API response", res);
      swal("success", "Record inserted", "success");
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in insert event", "error");
    });
};