import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import useStyles from "./SignInStyles";
import mainlogo from "../../logo-easydoctor.png";
import { signIn } from "../../actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://easydoctor.io/">
        easydoctor
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn(props) {
  const classes = useStyles();
  const userReducer = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [email, setEmail] = useState("");
  const [erroremail, seterroremail] = useState("");
  const [password, setPassword] = useState("");
  const [errorpassword, seterrorpassword] = useState("");
  const handleChange = (e) => {
    if (e.target.name == "email") {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };
  useEffect(() => {
    if (userReducer && userReducer.message) {
      setAlert(true);
      setAlertMsg(userReducer.message.message);
    }
    // if (userReducer && userReducer.user) {
    //   console.log("userReducer-=-=-=-=", userReducer.user);
    //   setAlert(true);
    //   setAlertMsg("Signed in successfully");
    // }
  }, [userReducer]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let validEmail = false;
    if (email == "") {
      seterroremail("Inserisci il tuo indirizzo email");
    } else if (reg.test(email) == false) {
      seterroremail("L'indirizzo email inserito non è valido");
    } else {
      seterroremail("");
      validEmail = true;
    }
    if (password == "") {
      seterrorpassword("Inserisci la tua password");
    } else if (password.length < 8) {
      seterrorpassword("La password deve avere almeno 8 caratteri");
    } else {
      seterrorpassword("");
    }
    if (
      email != "" &&
      validEmail == true &&
      password != "" &&
      password.length >= 8
    ) {
      await dispatch(signIn(email, password));
    }
  };
  if (localStorage.getItem("user") && localStorage.getItem("token")) {
   /* props.history.push("/dashboard");*/

   //verify the role
   var user = JSON.parse(localStorage.getItem("user"));
   var ispatient = false;
   for(var role in user.roles)
   {
      
      if(user.roles[role].name == 'patient')
      {
          ispatient = true;
      }
   }

   if(ispatient)
   {
        props.history.push("/download-app");
   }
   else
   {
        props.history.push("/calendar");
   }
   
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className="logo" 
          src={mainlogo} 
          alt="logo"/>
        <Typography component="h1" variant="h5" gutterBottom>
          Sign in
        </Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "center", color: "black", marginTop: "1rem" }}
          gutterBottom
        >
          Inserisci le tue credeziali per effettuare <br />
          l'accesso alla piattaforma
        </Typography>
        {alert ? (
          <Alert
            onClose={() => {
              setAlert(false);
            }}
            severity={
              alertMsg == "Signed in successfully" ? "success" : "error"
            }
            className={classes.alert}
          >
            {alertMsg}

            { alertMsg == "Il tuo account non è attivo." ? <a href="https://app.easydoctor.io/verify" >Clicca qui per attivare il tuo account.</a> : ""} 
            
            
          </Alert>
        ) : null}
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            value={email}
            onChange={(e) => {
              handleChange(e);
              var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
              if (e.target.value == "") {
                seterroremail("Inserisci il tuo indirizzo email");
              } else if (reg.test(e.target.value) == false) {
                seterroremail("L'indirizzo email non è valido");
              } else {
                seterroremail("");
              }
            }}
            label="Indirizzo email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {erroremail != "" ? (
            <p style={{ color: "red", margin: 0 }}>{erroremail}</p>
          ) : null}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                seterrorpassword("Inserisci la password");
              } else if (e.target.value.length < 8) {
                seterrorpassword("La password deve avere almeno 8 caratteri");
              } else {
                seterrorpassword("");
              }
            }}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />{" "}
          {errorpassword != "" ? (
            <p style={{ color: "red", margin: 0 }}>{errorpassword}</p>
          ) : null}
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            Accedi
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                href="/forgotPassword"
                variant="body2"
                className={classes.link}
              >
                Hai dimenticato la password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2" className={classes.link}>
                Non hai un account?
              </Link><br />
              <Link href="/signup" variant="body2" className={classes.link}>
                Clicca qui e registrati.
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
