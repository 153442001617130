import {
  INSERT_SUCCESS,
  GET_CALENDAR_SUCCESS,
  GET_PATIENT,
  GET_EVENT_RECORDS,
  GET_CLINIC,
} from "../actions/type";

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case INSERT_SUCCESS:
      return {
        ...state,
        city: action.payload,
      };

    case GET_CALENDAR_SUCCESS:
      return {
        ...state,
        calendar: action.payload,
      };

    case GET_PATIENT:
      return {
        ...state,
        myPatient: action.payload,
      };

    case GET_EVENT_RECORDS:
      return {
        ...state,
        eventRecord: action.payload,
      };

    case GET_CLINIC:
      return {
        ...state,
        myClinic: action.payload.data,
      };

    default:
      return state;
  }
}
