import React, { useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { TextField, Button } from "@material-ui/core";

import PersonOutlineOutlined from "@material-ui/icons/LockOutlined";
import useStyles from "./CredentialStyles";

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Link } from 'react-router-dom'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = [
//   "Oliver Hansen",
//   "Van Henry",
//   "April Tucker",
//   "Ralph Hubbard",
//   "Omar Alexander",
//   "Carlos Abbott",
//   "Miriam Wagner",
//   "Bradley Wilkerson",
//   "Virginia Andrews",
//   "Kelly Snyder",
// ];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function AddressForm(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    props.setUserInfo3({
      ...props.userInfo3,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} className={classes.icon}>
          <Typography variant="body1" gutterBottom style={{
              textAlign: "center",
              marginTop: "3.5rem",
              marginBottom: "2.5rem",
            }}>
            Specifica  l'email e la password che userai per accedere.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            type="email"
            id="firstName"
            name="email"
            label="Email"
            value={props.userInfo3.email}
            variant="outlined"
            onChange={(e) => {
              handleChange(e);
              var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
              if (e.target.value == "") {
                props.seterroremail("Please enter your email");
              } else if (reg.test(e.target.value) == false) {
                props.seterroremail("Invalid email address");
              } else {
                props.seterroremail("");
              }
            }}
            fullWidth
          />
          {props.erroremail != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>{props.erroremail}</p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            type="password"
            required
            id="lastName"
            name="password"
            variant="outlined"
            label="Password"
            // variant="filled"
            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                props.seterrorpassword("Please enter your password");
              } else if (e.target.value.length < 8) {
                props.seterrorpassword(
                  "Password must have atleast 8 characters"
                );
              } else {
                props.seterrorpassword("");
              }
            }}
            value={props.userInfo3.password}
            fullWidth
          />{" "}
          {props.errorpassword != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>
              {props.errorpassword}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            type="password"
            required
            id="lastName"
            variant="outlined"
            name="confpassword"
            label="Ripeti Password"
            // variant="filled"
            onChange={(e) => {
              handleChange(e);
              if (e.target.value !== props.userInfo3.password) {
                props.seterrorconfpassword("Passwords do no match");
              } else {
                props.seterrorconfpassword("");
              }
            }}
            value={props.userInfo3.confpassword}
            fullWidth
          />{" "}
          {props.errorconfpassword != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>
              {props.errorconfpassword}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
            <FormControlLabel
              value="1"
              required
              control={<Checkbox value="1" color="primary" required onChange={handleChange} name="termconditions" />}
              label={
                <div>
                   <span>Accetto </span>
                   <a href={'//api.easydoctor.io/terms'} target="_blank">i termini e le condizioni</a>
                   <span> e </span>
                   <a href={'//api.easydoctor.io/privacypolicy'} target="_blank">la privacy policy</a>
                </div>
                } 
              labelPlacement="End"
            />
            {props.errortermconditions != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>
              {props.errortermconditions}
            </p>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
