import { colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import shadows from "@material-ui/core/styles/shadows";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  textField:{
    marginBottom:"15px",
    color:"red"
  },
  button: {
    // marginTop: theme.spacing(3),
    background: "#2E9B92",
    borderRadius: "21px",
    color: "aliceblue",
    marginBottom:"10px",
    display:"flex",
    fontWeight:"600",
    justifyContent:"flex-end",
    marginLeft:"auto",
    // box-shadows:"none"

    "&:hover": {
      background: "#2E9B92",
      color: "white",
    },
  },
  Backbutton: {
    margin: "0 7px",
    borderRadius: "36px",
    background: "#fff",
    color: "#2E9B92",
    border: "0.25px solid #2E9B92",
    "&:hover": {
      color: "#2E9B92",
      background: "white",
    },
  },
  recordText:{
    display:"flex",
    marginBottom:"10px"
  }
}));

export default useStyles;