import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import meetingStyles from "../Dashboard/meeting/MeetingStyles";
import Title from "../Dashboard/Title";
import useStyles from "../Dashboard/DashboardStyles";
import Dashboard from "../Dashboard/Dashboard";
import ViewListIcon from '@material-ui/icons/ViewList'
import AddIcon from '@material-ui/icons/Add';
import { Box } from "@material-ui/core";
import { Container, Paper, Grid, TextField, Typography, Button, TextareaAutosize } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";



export default function ClinicalData(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const [notesArray, setallNotesArray] = useState([]);
  const [showArray, setShowArray] = useState([]);
  const meetingClasses = meetingStyles();
  const [isForm, setIsForm] = useState(true);

  const [reason, setReason] = useState("");
  const [anamnesis, setAnamnesis] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [conclusions, setConclusions] = useState("");
  const [roomLink, setRoomLink] = useState("");
  const [roomToken, setRoomToken] = useState("");
  const [isJoin, setIsJoin] = useState(false);

  const [errorReason, setErrorReason] = useState(false);
  const [errorAnamnesis, setErrorAnamnesis] = useState(false);
  const [errorDiagnosis, setErrorDiagnosis] = useState(false);
  const [errorSuggestion, setErrorSuggestion] = useState(false);
  const [errorConclusions, setErrorConclusions] = useState(false);

  const [apiCall, setApiCall] = useState(false);

  const loadClinicaldata = async () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/clinicaldata`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
            
          setShowArray(JSON.parse(res.data.chino_response).data.documents);
        })
        .catch((err) => {
          console.log(err)
        });
    }
  
    loadClinicaldata();
  return (
    <div className={classes.root}>
      <Dashboard />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} >
              <div className="sub_part">
                  <>
                    {
                      showArray.length > 0 ?
                        showArray.map((element) => {
                          return <div className="details_section" style={{ marginTop: "15px" }}>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Motivo visita</div>:<div style={{whiteSpace:"break-spaces"}}> {element.reason} </div>
                            </Typography>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Anamnesi</div>:{ }<div style={{whiteSpace:"break-spaces"}}> {element.anamnesis} </div>
                            </Typography>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Diagnosi</div>:<div style={{whiteSpace:"break-spaces"}}>{element.diagnosis} </div>
                            </Typography>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Suggerimento Terapeutico</div>:<div style={{whiteSpace:"break-spaces"}}> {element.suggestion} </div>
                            </Typography>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Conclusioni/indicazioni</div>:<div style={{whiteSpace:"break-spaces"}}> {element.conclusions}</div>
                            </Typography>
                          </div>
                        }) :
                        <Typography component="p" variant="body1" style={{ textAlign: "center" }}>
                          nessun record trovato
                    </Typography>
                    }
                  </>
                

              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
