import axios from "axios";
import { returnErrors } from "./ErrorActions";
import {
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  LOGOUT,
  ALL_SPECIALIZATIONS_SUCCESS,
  ALL_CITIES_SUCCESS,
  USER_DETAILS_UPDATE,
  VERIFY_SUCCESS,
  VERIFY_ERROR
} from "./type";
import swal from "sweetalert";

export const signIn = (email, password) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/login`, { email, password })
    .then((res) => {
      dispatch({ type: SIGNIN_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch(returnErrors(err.message, "400", "SIGNIN_ERROR"));
      dispatch({ type: SIGNIN_ERROR, payload: err.message });
    });
};

export const verifyProfile = (email) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/profile/sendemail`, { email })
    .then((res) => {
      dispatch({ type: VERIFY_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch(returnErrors(err.message, "400", "VERIFICATION_ERROR"));
      dispatch({ type: VERIFY_ERROR, payload: err.message });
    });
};

export const confirmProfile = (url) => (dispatch) => {
  url = url.replace("app.easydoctor.io/confirm","api.easydoctor.io/api/profile/confirm");
  axios
    .get(url)
    .then((res) => {
      dispatch({ type: VERIFY_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in signup", "error");
    });
}; 

export const deleteProfile = (url) => (dispatch) => {
  url = url.replace("app.easydoctor.io/delete","api.easydoctor.io/api/profile/delete/confirm");
  axios
    .get(url)
    .then((res) => {
      dispatch({ type: VERIFY_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in signup", "error");
    });
};

export const signUpAction = (values) => (dispatch) => {
  console.log("value", values.name);
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/register`, values)
    .then((res) => {
      console.log("res",res.data.emailcheck);
      if(res.data.emailcheck!==undefined){
        swal("Oops!", "Email is already exists", "error");
      }else{
        window.location.replace("/verify");
      }
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in signup", "error");
    });
};

export const userDetailsForUpdate = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/userprofile`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }})
    .then((res) => {
      dispatch({ type: USER_DETAILS_UPDATE, payload: res.data.userprofile });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong for get user details", "error");
    });
};

export const updateUserDetails = (value) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/updateuserinfo`, value,{
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }})
    .then((res) => {
      swal("Accepted!", "Update successfully", "success");
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in updetails", "error");
    });
};
export const allSpecializations = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/specializations/all`)
    .then((res) => {
      dispatch({ type: ALL_SPECIALIZATIONS_SUCCESS, payload: res });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong for get specilization", "error");
    });
};

export const allCities = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/world/cities`)
    .then((res) => {
      dispatch({ type: ALL_CITIES_SUCCESS, payload: res });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong for get all city", "error");
    });
};

export const sendInvitationLink = (values) => (dispatch) => {
  swal({
    title: "processing...",
    text: "Plase wait for some time",
    icon: "warning",
    dangerMode: true,
    closeOnClickOutside: false,
    buttons: false,
  });
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/sendlink`, values, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((res) => {
      swal("Invitation!", "Invitation mail send successfully", "success");
      // dispatch({ type: ALL_CITIES_SUCCESS, payload: res });
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in mail send", "error");
      // dispatch(returnErrors(err.message, "400", "SIGNIN_ERROR"));
      // dispatch({ type: SIGNIN_ERROR, payload: err.message });
    });
};

export const addPatientData = (values) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/storepatient`, values)
    .then((res) => {
      swal("Accepted!", "Accept request successfully", "success");
    })
    .catch((err) => {
      swal("Oops!", "Something wrong in accept request", "error");
    });
};

export const logout = () => (dispatch) => {

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/logout`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }})
    .then((res) => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      dispatch({ type: LOGOUT });
      window.location.replace("/");
    })
    .catch((err) => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      dispatch({ type: LOGOUT });
      window.location.replace("/");
    });
};

export const sendDeleteLink = (values) => (dispatch) => {
  swal({
    title: "processing...",
    text: "Plase wait for some time",
    icon: "warning",
    dangerMode: true,
    closeOnClickOutside: false,
    buttons: false,
  });
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/profile/delete`, values, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((res) => {
      swal("Cancellazione profilo", "Ti è stata inviata un email per confermare la cancellazione del profilo", "success");
      // dispatch({ type: ALL_CITIES_SUCCESS, payload: res });
    })
    .catch((err) => {
      swal("Oops!", "Si è verificato un errore", "error");
      // dispatch(returnErrors(err.message, "400", "SIGNIN_ERROR"));
      // dispatch({ type: SIGNIN_ERROR, payload: err.message });
    });
};
