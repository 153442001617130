import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  Container,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Badge,
  Typography,
  RadioGroup,
  Radio,
  Paper,
  Box,
} from "@material-ui/core";
import 'moment/locale/it';

import ImportantDevicesOutlined from "@material-ui/icons/ImportantDevicesOutlined";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Title from "../Title";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import format from 'date-fns/format'
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import useStyles from "./CalendarStyles";
import Dashboard from "../Dashboard";
import useDashboardStyles from "../DashboardStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  insertCalendarData,
  updateCalendarData,
  getCalendarData,
  getMyPatient,
  getMyClinic,
  deleteCalendarEvent,
} from "../../../actions/CalendarActions";


const localizer = momentLocalizer(moment);


const allViews = ["day","week","month", "agenda"];
const formats = {
  timeGutterFormat: 'HH:mm',
  dayFormat: (date, culture, localizer) =>localizer.format(date, 'dddd DD', culture),
  weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
  dayHeaderFormat: (date, culture, localizer) =>localizer.format(date, 'dddd MMMM, DD', culture),
  eventTimeRangeFormat: range =>
    `${format(range.start, 'HH:mm')} – ${format(range.end, 'HH:mm')}`,
};
 
const messages = {
  previous: 'precedente',
  next: 'Il prossimo',
  today: 'Oggi',
  month: 'mese',
  week: 'settimana',
  day: 'giorno',
  agenda: 'agenda',
};


export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
export default function Deposits() {
  const dispatch = useDispatch();
  let history = useHistory();
  const calendarReducer = useSelector((state) => {
    return state.calendar;
  });
  const dashboardClasses = useDashboardStyles();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openId, setOpenid] = useState(null);
  const [eventId, setEventid] = useState(null);
  const [newEvent, setNewEvent] = useState({
    date: "",
    title: "",
    online: true,
    clinic: false,
    start: "",
    end: "",
    price: "",
    studioName: "",
    desc: "",
    status: "planned",
    patientId: ""
  });
  const [currentViewInterval, setCurrentViewInterval] = useState({});

  const [myPatient, setMyPatient] = useState([]);
  const [office, setOffice] = useState([]);
  const [error, setError] = useState(false);


  const [planCount, setPlanCount] = useState(0);
  const [reserveCount, setReserveCount] = useState(0);
  const [pausesCount, setPausesCount] = useState(0);
  const [myEventsList, setEvents] = useState([]);


  useEffect(async () => {
    var start = moment().startOf('isoWeek');
    var end = moment().endOf('isoWeek');
    console.log("current Date", start._d, end._d);
    setCurrentViewInterval({ start: start._d, end: end._d })

    await dispatch(
      getCalendarData(
        JSON.parse(localStorage.getItem("user")).id,
        localStorage.getItem("token")
      )
    );
    await dispatch(getMyPatient(localStorage.getItem("token")));
    await dispatch(getMyClinic(localStorage.getItem("token")));
  }, []);

  useEffect(async () => {
    let plan = 0
    let reserve = 0
    let pause = 0
    if (currentViewInterval.start !== undefined) {
      myEventsList.map((element) => {
        if (moment(moment(element.start).format("YYYY-MM-DD")).isBetween(moment(currentViewInterval.start).format("YYYY-MM-DD"), moment(currentViewInterval.end).format("YYYY-MM-DD"), undefined, '[]') === true) {
          if (element.status === "planned")
            plan += 1
          if (element.status === "booked")
            reserve += 1
          if (element.status === "pause")
            pause += 1
        }
      })
    }
    setPlanCount(plan)
    setReserveCount(reserve)
    setPausesCount(pause)
  }, [currentViewInterval, myEventsList]);

  useEffect(async () => {
    if (calendarReducer) {
      if (calendarReducer.calendar) {
        let result = calendarReducer.calendar.data.calendar.map((o, index) => ({
          id: index,
          date: o.date.split(" ")[0],
          title: o.patientname,
          online: o.online == 1 ? true : false,
          clinic: o.clinic == 1 ? true : false,
          start: new Date(o.date.split(" ")[0] + "T" + o.start),
          end: new Date(o.date.split(" ")[0] + "T" + o.end),
          price: o.price,
          studioName: o.office,
          desc: o.desc,
          status: o.status,
          eventId: o.id,
          patientId: o.patient
        }));
        setEvents(result);
      }
      if (calendarReducer.myPatient) {
        setMyPatient(calendarReducer.myPatient.data.patientname);
      }
      if (calendarReducer.myClinic) {
        for (let i = 0; i < calendarReducer.myClinic.address.length; i++) {
          const element = calendarReducer.myClinic.address[i];
          if (element.clinic_name === null) {
            element.clinic_name = element.city
          }
        }
        setOffice(calendarReducer.myClinic.address);
      }
    }
  }, [calendarReducer]);

  // const [myEventsList, setEvents] = useState([
  //   {
  //     id: 0,
  //     title: "Conference",
  //     start: new Date(2021, 1, 19, 0, 0, 0),
  //     end: new Date(2021, 1, 19, 6, 0, 0),
  //     desc: "Big conference for important people",
  //     status: "planned",
  //   },
  //   {
  //     id: 1,
  //     title: "Meeting",
  //     start: new Date(2021, 1, 19, 0, 0, 0),
  //     end: new Date(2021, 1, 19, 12, 0, 0),
  //     desc: "Pre-meeting meeting, to prepare for the meeting",
  //     status: "booked",
  //   },
  //   {
  //     id: 2,
  //     title: "Lunch",
  //     start: new Date(2021, 1, 18, 12, 0, 0, 0),
  //     end: new Date(2021, 1, 18, 13, 0, 0, 0),
  //     desc: "Power lunch",
  //     status: "pause",
  //   },
  // ]);
  const [editEvent, setEditEvent] = useState(false);
  const [editAvailable, setAvailable] = useState(false); //available

  const handleClose = () => {
    setEditEvent(false);
    setOpen(false);
    setAvailable(false); //available
    setNewEvent({
      date: "",
      title: "",
      online: true,
      clinic: false,
      start: "",
      end: "",
      price: "",
      studioName: "",
      desc: "",
      status: "planned",
      patientId: ""
    });
  };

  const handleClickAvailable = () => { //available
    setEditEvent(false);
    setOpen(true);
    setAvailable(true);
    setNewEvent({
      date: "",
      title: "",
      online: true,
      clinic: false,
      start: "",
      end: "",
      price: "",
      studioName: "",
      desc: "",
      status: "planned",
      patientId: ""
    });
  };

  const handleChange = (e) => {
    setNewEvent({
      ...newEvent,
      [e.target.name]: e.target.value,
    });

    if(e.target.name=="status")
    {
      if(e.target.value == "booked")
      {
        setAvailable(false);
      }
      else
      {
        setAvailable(true);
      }
    }

    setError(false);
  };

  const handleSelect = (event) => {
    var date = moment(event.start).format("YYYY-MM-DD");
    var startTime = moment(event.start).format("HH:mm:ss");
    var endTime = moment(event.end).format("HH:mm:ss");
    setNewEvent({
      ...newEvent,
      date: date,
      start: startTime,
      end: endTime,
    });
    setOpen(true);
    setAvailable(true);
  };

  const handleSelectEvent = (event) => {
    var startTime = moment(event.start).format("HH:mm:ss");
    var endTime = moment(event.end).format("HH:mm:ss");
    setOpenid(event.id);
    setEventid(event.eventId);
    setOpen(true);
    setNewEvent({
      date: event.date,
      title: event.title,
      online: event.online,
      clinic: event.clinic,
      start: startTime,
      end: endTime,
      price: event.price,
      studioName: event.studioName,
      desc: event.desc,
      status: event.status,
      patientId: event.patientId
    });
    setEditEvent(true);
  };

  const handleDeleteEvent = async () => {
    let index = myEventsList.findIndex((event) => event.id == openId);
    if (index > -1) {
      myEventsList.splice(index, 1);
    }
    await dispatch(deleteCalendarEvent(eventId, localStorage.getItem("token")));
    setOpen(false);
  };

  const handleSaveEvent = async () => {
    
    if(newEvent.status == "available" || newEvent.status == "planned" || newEvent.status == "booked")
    {
      if(newEvent.price === "")
      {
          setError(true);
      }
    }

    if (newEvent.status == "planned" && newEvent.price !="") {
      var values = {
        date: newEvent.date,
        // patient: newEvent.patientId,
        online: newEvent.online,
        clinic: newEvent.clinic,
        start: newEvent.start,
        end: newEvent.end,
        price: newEvent.price,
        office: newEvent.studioName,
        desc: newEvent.desc,
        status: newEvent.status,
      };
      if (newEvent.patientId !== "") {
        values = {
          ...values,
          patient: newEvent.patientId
        }
      }

      axios
        .post(`${process.env.REACT_APP_API_URL}/api/storecalendar`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setEvents([
            ...myEventsList,
            {
              id: myEventsList.length,
              date: newEvent.date,
              title: newEvent.title,
              online: newEvent.online,
              clinic: newEvent.clinic,
              start: new Date(newEvent.date + "T" + newEvent.start),
              end: new Date(newEvent.date + "T" + newEvent.end),
              price: newEvent.price,
              studioName: newEvent.studioName,
              desc: newEvent.desc,
              status: newEvent.status,
              eventId: res.data.lastid,
              patientId: newEvent.patientId
            },
          ]);
          setNewEvent({
            date: "",
            title: "",
            online: true,
            clinic: false,
            start: "",
            end: "",
            price: "",
            studioName: "",
            desc: "",
            status: "planned",
            patientId: ""
          });
          setAvailable(false);
          setOpen(false);
        })
        .catch((err) => {
          alert("some problem in insert calendar");
        });
    }
    else {
    if (newEvent.patientId == "" && newEvent.status != "pause") {
      setError(true)
    } else {
      if (!editEvent) {

        var values = {
          date: newEvent.date,
          // patient: newEvent.patientId,
          online: newEvent.online,
          clinic: newEvent.clinic,
          start: newEvent.start,
          end: newEvent.end,
          price: newEvent.price,
          office: newEvent.studioName,
          desc: newEvent.desc,
          status: newEvent.status,
        };
        if (newEvent.patientId !== "") {
          values = {
            ...values,
            patient: newEvent.patientId
          }
        }

        axios
          .post(`${process.env.REACT_APP_API_URL}/api/storecalendar`, values, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setEvents([
              ...myEventsList,
              {
                id: myEventsList.length,
                date: newEvent.date,
                title: newEvent.title,
                online: newEvent.online,
                clinic: newEvent.clinic,
                start: new Date(newEvent.date + "T" + newEvent.start),
                end: new Date(newEvent.date + "T" + newEvent.end),
                price: newEvent.price,
                studioName: newEvent.studioName,
                desc: newEvent.desc,
                status: newEvent.status,
                eventId: res.data.lastid,
                patientId: newEvent.patientId
              },
            ]);
            setNewEvent({
              date: "",
              title: "",
              online: true,
              clinic: false,
              start: "",
              end: "",
              price: "",
              studioName: "",
              desc: "",
              status: "planned",
              patientId: ""
            });
            setOpen(false);
          })
          .catch((err) => {
            alert("some problem in insert calendar");
          });
        // setNewEvent({
        //   online: false,
        // });
      } else {
        console.log("open iD", openId);
        let index = myEventsList.findIndex((event) => event.id == openId);
        var values = {
          date: newEvent.date,
          // patientname: newEvent.patientId,
          online: newEvent.online,
          clinic: newEvent.clinic,
          start_time: newEvent.start,
          end_time: newEvent.end,
          price: newEvent.price,
          studio_name: newEvent.studioName,
          notes: newEvent.desc,
          status: newEvent.status,
          calendarid: eventId,
        }

        if (newEvent.patientId !== "") {
          values = {
            ...values,
            patientname: newEvent.patientId
          }
        }
        await dispatch(
          updateCalendarData(values)
        );
        if (index > -1) {
          myEventsList.splice(index, 1);
          setEvents([
            ...myEventsList,
            {
              id: openId,
              date: newEvent.date,
              title: newEvent.title,
              online: newEvent.online,
              clinic: newEvent.clinic,
              start: new Date(newEvent.date + "T" + newEvent.start),
              end: new Date(newEvent.date + "T" + newEvent.end),
              price: newEvent.price,
              studioName: newEvent.studioName,
              desc: newEvent.desc,
              status: newEvent.status,
              patientId: newEvent.patientId
            },
          ]);
        }
      }
      }
    }

    /*
      setNewEvent({
        date: "",
        title: "",
        online: true,
        clinic: false,
        start: "",
        end: "",
        price: "",
        studioName: "",
        desc: "",
        status: "planned",
        newEvent: ""
      });*/
      
  };

  return (
    <div className={dashboardClasses.root}>
      {}
      <Dashboard />
      <main className={dashboardClasses.content}>
        <div className={dashboardClasses.appBarSpacer} />
        <Container maxWidth="lg" className={dashboardClasses.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper>
                <Container style={{ padding: "30px" }}>
                  {/* <Title>Calendar</Title>{" "} */}

                  <Grid container className={classes.grid}>
                    <Grid item xs={12} sm={8} className={classes} style={{ display:"none" }}> 
                      <Typography
                        component="p"
                        variant="body1"
                        color="inherit"
                        noWrap
                        style={{ marginRight: "15px" }}
                      >
                        <FiberManualRecord
                          style={{
                            color: "#66E2C3",
                            verticalAlign: "top",
                            lineHeight: "1.25em",
                            height: "24px",
                          }}
                        />{" "}
                        <strong>
                          {/* {
                            myEventsList.filter(
                              (obj) => obj.status === "planned"
                            ).length
                          } */}
                          {planCount}
                        </strong>{" "}
                        Disponibilità programmate
                      </Typography>
                      {/* </Grid>
                        <Grid item xs={12} sm={3}> */}
                      <Typography
                        component="p"
                        variant="body1"
                        color="inherit"
                        noWrap
                        style={{ marginRight: "15px" }}
                      >
                        <FiberManualRecord
                          style={{
                            color: "#FDE250",
                            verticalAlign: "top",
                            lineHeight: "1.25em",
                            height: "24px",
                          }}
                        />{" "}
                        <strong>
                          {" "}
                          {/* {
                            myEventsList.filter((obj) => obj.status === "pause")
                              .length
                          } */}
                          {pausesCount}
                        </strong>{" "}
                        Disponibilità in pausa
                      </Typography>
                      {/* </Grid> <Grid item xs={12} sm={3}> */}
                      <Typography
                        component="p"
                        variant="body1"
                        color="inherit"
                        noWrap
                        style={{ marginRight: "15px" }}
                      >
                        <FiberManualRecord
                          style={{
                            color: "#3682B6",
                            verticalAlign: "top",
                            lineHeight: "1.25em",
                            height: "24px",
                          }}
                        />{" "}
                        <strong>
                          {" "}
                          {/* {
                            myEventsList.filter(
                              (obj) => obj.status === "booked"
                            ).length
                          } */}
                          {reserveCount}
                        </strong>{" "}
                        Visita prenotata
                      </Typography>
                      {/* </Grid> */}
                      {/* <Grid item xs={12} sm={6}> */}
                    </Grid>
                    {/* <Grid item xs={12} sm={4} className={classes.header}>
                      <Button
                        onClick={() => {
                          handleClickAvailable(); //available
                          setOpen(true);
                        }}
                        color="primary"
                        variant="contained"
                        className={classes.button}
                      >
                        Programmazione
                      </Button>
                      </Grid>*/}
                  </Grid>

                  <Calendar
                    selectable
                    localizer={localizer}
                    formats={formats}
                    events={myEventsList}
                    showMultiDayTimes
                    defaultView={Views.WEEK}
                    views={allViews}
                    messages={messages}
                    culture='it'
                    startAccessor="start"
                    endAccessor="end"
                    step={30}
                    onSelectEvent={handleSelectEvent}
                    onSelectSlot={handleSelect}
                    min={new Date(2017, 10, 0, 6, 0, 0)}
                    max={new Date(2017, 10, 0, 20, 0, 0)} 
                    onRangeChange={(e) => {
                      console.log("onRangeChange", e)
                      if (e.start === undefined) {
                        var inteval = { start: e[0], end: e[e.length - 1] }
                        console.log("inter val", inteval);
                        setCurrentViewInterval(inteval)
                      } else {
                        setCurrentViewInterval(e)
                      }
                    }}
                    eventPropGetter={(event) => {
                       let backgroundColor;
                      let boxShadow;
                      if (event.status == "planned") {
                         backgroundColor = "#fff";
                        //boxShadow = "inset 0 0 10px #66E2C3"
                      }
                      if (event.status == "booked") {
                         backgroundColor = " #fff";
                        //boxShadow = "inset 0 0 10px #3682B6"
                      }
                      if (event.status == "pause") {
                         backgroundColor = " #fff";
                        //boxShadow = "inset 0 0 10px #FDE250"
                      }
                      if (event.status == "available") {
                         backgroundColor = "rgba(221,221,221,0.51)";
                      }
                      return {
                        style: {
                          backgroundColor:backgroundColor,
                          color: "#1a5175",
                          border: "2px solid #ddd",
                          paddingLeft:"10px",
                          fontWeight:"600",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        },
                      };
                    }}
                  />
                  <Dialog
                    // fullWidth={true}
                    // maxWidth={"sm"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Inserimento nuova disponibilitá
                    </DialogTitle>
                    <DialogContent>
                      <Grid item xs={12} sm={12}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Status</FormLabel>
                          <RadioGroup
                            aria-label="gender"
                            name="status"
                            style={{ display: "flex", flexDirection: "row" }}
                            value={newEvent.status}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="planned"
                              control={<Radio color="primary" />}
                              label="Programmate"
                            />
                            <FormControlLabel
                              value="booked"
                              control={<Radio color="primary" />}
                              label="Prenotata"
                            />
                            <FormControlLabel
                              value="pause"
                              control={<Radio color="primary" />}
                              label="Pause"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid container spacing={3}>
                        {!editAvailable ?
                          <Grid item xs={12} sm={12}>
                            {/* <TextField
                            autoFocus
                            value={newEvent.title}
                            onChange={handleChange}
                            name="title"
                            label="Inserisci paziente"
                            type="text"
                            fullWidth
                            required
                            variant="outlined"
                          /> */}
                            <Autocomplete
                              id="tags-outlined"
                              options={myPatient}
                              getOptionLabel={(option) => option.name}
                              // defaultValue={editEvent ? [myPatient[0].name] : ""}
                              // defaultValue={indexEventData.patient}
                              // defaultValue={{id: 1, name: "test1"}}

                              defaultValue={
                                myPatient.filter((element, index) => {
                                  if (element.id === newEvent.patientId) {
                                    return element
                                  }
                                })[0]}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setNewEvent({
                                    ...newEvent,
                                    title: newValue.name,
                                    patientId: newValue.id
                                  });
                                  setError(false)
                                } else {
                                  console.log("else");
                                  setNewEvent({
                                    ...newEvent,
                                    title: "",
                                    patientId: ""
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Inserisci paziente"
                                  variant="outlined"
                                  required
                                  error={error}
                                  helperText={error ? 'Empty field!' : ' '}
                                />
                              )}
                            />
                          </Grid> : ""
                        }
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Data"
                            type="date"
                            name="date"
                            onChange={handleChange}
                            value={newEvent.date}
                            defaultValue={newEvent.date}
                            fullWidth
                            required
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Ora di inizio"
                            type="time"
                            name="start"
                            onChange={handleChange}
                            value={newEvent.start}
                            // defaultValue={newEvent.start}
                            fullWidth
                            required
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="name"
                            label="Ora di fine"
                            name="end"
                            onChange={handleChange}
                            value={newEvent.end}
                            // defaultValue={newEvent.end}
                            type="time"
                            fullWidth
                            required
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        </Grid>
                        
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style= {newEvent.status == "pause" ? { display:"none" } : { paddingBottom: "0" } }
                          
                        >
                          
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <FormLabel component="legend">
                              Assign responsibility
                            </FormLabel>
                            <FormGroup className={classes.formgroup}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={newEvent.online}
                                    onChange={() => {
                                      setNewEvent({
                                        ...newEvent,
                                        online: !newEvent.online,
                                      });
                                    }}
                                    name="gilad"
                                    color="primary"
                                  />
                                }
                                label="Online"
                              >
                                <Badge badgeContent={4} color="secondary">
                                  <ImportantDevicesOutlined />
                                </Badge>
                              </FormControlLabel>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={newEvent.clinic}
                                    onChange={() => {
                                      setNewEvent({
                                        ...newEvent,
                                        clinic: !newEvent.clinic,
                                      });
                                    }}
                                    name="jason"
                                  />
                                }
                                label="Clinica / Studio"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} style= {newEvent.status == "pause" ? { display:"none" } : { paddingTop: "0" } }>
                          <TextField
                            id="price"
                            label="Prezzo"
                            onChange={handleChange}
                            value={newEvent.price}
                            name="price"
                            type="number"
                            fullWidth
                            required
                            variant="outlined"
                          />
                        </Grid>
                        {newEvent.clinic === true ? (
                          <Grid item xs={12} sm={6} style={{ paddingTop: "0" }}>
                            {/* <TextField
                              onChange={handleChange}
                              value={newEvent.studioName}
                              name="studioName"
                              label="Nome Studio"
                              type="text"
                              fullWidth
                              required
                              variant="outlined"
                            /> */}
                            <Autocomplete
                              id="combo-box-demo"
                              options={office}
                              getOptionLabel={(option) => option.clinic_name}
                              // style={{ width: 300 }}
                              // defaultValue={office.find(v => v.name[0])}
                              // defaultValue={office[newEvent.studioName]}
                              defaultValue={
                                office.filter((element, index) => {
                                  if (element.id === newEvent.studioName) {
                                    return element
                                  }
                                })[0]}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setNewEvent({
                                    ...newEvent,
                                    studioName: newValue.id,
                                  });
                                } else {
                                  setNewEvent({
                                    ...newEvent,
                                    studioName: "",
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Nome Studio"
                                  variant="outlined"
                                />
                              )}
                            />

                          </Grid>
                        ) : (
                          ""
                        )}
                        
                          <>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                fullWidth
                                onChange={handleChange}
                                value={newEvent.desc}
                                name="desc"
                                id="outlined-multiline-static"
                                label="Notes"
                                multiline
                                rows={4}
                                variant="outlined"
                              />
                            </Grid>
                            
                          </>
                        
                      </Grid>
                      {/* <DialogContentText>
                      To subscribe to this website, please enter your email address here.
                      We will send updates occasionally.
                    </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        color="primary"
                        variant="contained"
                        className={classes.Backbutton}
                      >
                        Cancel
                      </Button>
                      {editEvent ? (
                        <Button
                          onClick={handleDeleteEvent}
                          color="primary"
                          variant="contained"
                          className={classes.button}
                        >
                          Delete
                        </Button>
                      ) : null}

                      <Button
                        onClick={handleSaveEvent}
                        color="primary"
                        variant="contained"
                        className={classes.button}
                      >
                        Salva
                      </Button>

                      {editEvent ? (
                        <Button
                          onClick={() => { history.push("/meeting", { eventId: eventId, patientId: newEvent.patientId }) }}
                          color="primary"
                          variant="contained"
                          className={classes.button}
                        >
                          Incontro
                        </Button>
                      ) : null}

                    </DialogActions>
                  </Dialog>
                </Container>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
