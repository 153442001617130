import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AcceptRequest from "./AcceptRequestStyles";
import { signIn } from "../../actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { addPatientData } from "../../actions/UserActions";
import mainlogo from "../../logo-easydoctor.png";

export default function Accept(props) {
  const classes = AcceptRequest();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    let url = new URLSearchParams(props.location.search);
    await dispatch(
      addPatientData({
        name: url.get("name"),
        birth_date: url.get("birth_date"),
        fiscal_id: url.get("id"),
        phone: url.get("phone"),
        email: url.get("email"),
      })
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      
      <CssBaseline />
      <div className={classes.paper}>
        <img className="logo" 
            src={mainlogo} 
            alt="logo"/>
        <Typography component="h1" variant="h5" gutterBottom>
          Invito
        </Typography>
        <Typography
          variant="body1"
          style={{
            textAlign: "center",
            color: "black",
            marginTop: "1rem",
          }}
          gutterBottom
        >
          Accetta l'invito del tuo dottore a entrare a easydoctor
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => handleSubmit()}
        >
          Accetta
        </Button>
        
      </div>
    </Container>
  );
}
