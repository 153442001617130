import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/ExitToAppOutlined";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/HomeOutlined";
import ShoppingCartIcon from "@material-ui/icons/EventOutlined";
import PeopleIcon from "@material-ui/icons/People";
import UpdateIcon from "@material-ui/icons/Update";
import useStyles from "./DashboardStyles";
import { useHistory } from "react-router-dom";
import { logout as LogoutAction } from "../../actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import mainlogo from "../../logo-easydoctor.png";

export default function Dashboard(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  let history = useHistory();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = () => {
    dispatch(LogoutAction());
    history.push("/");
    
  };

  const drawerWidth = 240;

  return (
    <>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          
        <img className="logo" 
            src={mainlogo} 
            alt="logo" style={{marginleft:"30px"}}/>
         
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(open && classes.drawerPaper,classes.drawerPaperClose ),
          root: clsx(open && classes.drawerPaper,classes.drawerPaperClose ),
        }}
        open={open}
      >

        <div className={clsx(classes.toolbarIcon,classes.toolbarIconShow)}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
            style={{marginRight:"10px"}}
          >
            <MenuIcon />
          </IconButton>
          <IconButton onClick={handleDrawerClose} style={ open?{}:{display:"none"}} >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {/*<ListItem
            button
            className={
              window.location.pathname == "/dashboard"
                ? classes.activeDashboard
                : ""
            }
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>*/}
          <ListItem
            className={
              window.location.pathname == "/calendar"
                ? classes.activeDashboard
                : ""
            }
            button
            onClick={() => {
              history.push("/calendar");
            }}
          >
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Calendario" />
          </ListItem>
          <ListItem
            className={
              window.location.pathname == "/invite"
                ? classes.activeDashboard
                : ""
            }
            button
            onClick={() => {
              history.push("/invite");
            }}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Pazienti" />
          </ListItem>
          
          <ListItem
            className={
              window.location.pathname == "/updateUser"
                ? classes.activeDashboard
                : ""
            }
            button
            onClick={() => {
              history.push("/updateUser");
            }}
          >
            <ListItemIcon>
              <UpdateIcon />
            </ListItemIcon>
            <ListItemText primary="Profilo" />
          </ListItem>
          <ListItem
            button
            onClick={logout}
          >
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
