import logo from "./logo.svg";
import "./App.css";
import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import SignIn from "./components/signin/SignIn";
import SignUp from "./components/signup/SignUp";
import Verification from "./components/signup/Verification";
import DownloadApp from "./components/Dashboard/DownloadApp";
import Confirm from "./components/signup/Confirm";
import Delete from "./components/signup/Delete";
import Accept from "./components/AcceptRequest/AcceptRequest";
// import UpdateUser from "./components/updateUser/UpdateUser";
import Dashboard from "./components/Dashboard/Home/Home";
import Calendar from "./components/Dashboard/Calendar/Calendar";
import Invite from "./components/Dashboard/Invite/Invite";
import UpdateUser from "./components/Dashboard/UpdateUser/UpdateUser";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import ForgotPassword from "./components/PasswordRecovery/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/PasswordRecovery/ResetPassword/ResetPassword";
import Meeting from "./components/Dashboard/meeting/Meeting";
import ClinicalData from "./components/Patient/ClinicalData";

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        color: '#6d6d6d',
      },
    },
  },
  palette: {
    primary: { main: "#6d6d6d" },
    
    // secondary: {
    //   // This is green.A700 as hex.
    //   main: "#B3D3",
    // },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/" component={SignIn}></Route>
          <Route exact path="/acceptRequest" component={Accept}></Route>
          <Route exact path="/signin" component={SignIn}></Route>
          <Route exact path="/signup" component={SignUp}></Route>
          <Route exact path="/verify" component={Verification}></Route>
          <Route exact path="/download-app" component={DownloadApp}></Route>
          <Route path="/confirm/:token" component={Confirm}></Route>
          <Route path="/delete/:token" component={Delete}></Route>
          <PrivateRoute
            exact
            path="/dashboard"
            component={Dashboard}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/updateUser"
            component={UpdateUser}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/calendar"
            component={Calendar}
          ></PrivateRoute>
          <PrivateRoute exact path="/invite" component={Invite}></PrivateRoute>
          <PrivateRoute exact path="/meeting" component={Meeting}></PrivateRoute>
          <PrivateRoute exact path="/clinicaldata" component={ClinicalData}></PrivateRoute>
          <Route exact path="/resetPassword" component={ResetPassword}></Route>
          <Route
            exact
            path="/forgotPassword"
            component={ForgotPassword}
          ></Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

export default App;
