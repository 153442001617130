import React from "react";
import { CssBaseline } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Registry from "./Registry/Registry";
import WorkActivity from "./WorkActivity/WorkActivity";
import Credentials from "./Credentials/Credentials";
import useStyles from "./SignUpStyles";
import { useDispatch, useSelector } from "react-redux";
import { signUpAction } from "../../actions/UserActions";
import mainlogo from "../../logo-easydoctor.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://easydoctor.io/">
        easydoctor
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = ["Anagrafica", "Attività di lavoro", "Credenziali di accesso"];

export default function Checkout(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [userInfo1, setUserInfo1] = React.useState({
    name: "",
    surname: "",
    birthDate: "",
    phoneNumber: "",
    alternateNumber: "",
    specialization: [],
  });

  const [userInfo2, setUserInfo2] = React.useState({
    name: "",
    city: "",
    address: "",
    CAP: "",
  });

  const [userInfo3, setUserInfo3] = React.useState({
    email: "",
    password: "",
    confpassword: "",
    termconditions: false
  });

  const [online, setOnline] = React.useState(true);
  const [clinic, setClinic] = React.useState(false);

  const [errorName, seterrorName] = React.useState("");
  const [errorSurname, seterrorSurname] = React.useState("");
  const [errorBirthDate, seterrorBirthDate] = React.useState("");
  const [errorPhoneNumber, seterrorPhoneNumber] = React.useState("");
  const [errorSpecialization, seterrorSpecialization] = React.useState("");

  const [errorCity, seterrorCity] = React.useState("");
  const [errorAddress, seterrorAddress] = React.useState("");
  const [errorCAP, seterrorCAP] = React.useState("");

  const [erroremail, seterroremail] = React.useState("");
  const [errorpassword, seterrorpassword] = React.useState("");
  const [errorconfpassword, seterrorconfpassword] = React.useState("");
  const [errortermconditions, seterrortermconditions] = React.useState("");

  const handleNext = async () => {
    if (activeStep == 0) {
      if (
        userInfo1.name !== "" &&
        userInfo1.surname !== "" &&
        userInfo1.birthDate !== "" &&
        userInfo1.phoneNumber !== "" &&
        userInfo1.specialization.length !== 0
      ) {
        setActiveStep(activeStep + 1);
      } else {
        if (userInfo1.name == "") {
          seterrorName("Inserisci il tuo nome");
        }
        if (userInfo1.surname == "") {
          seterrorSurname("Inserisci il tuo cognome");
        }
        if (userInfo1.birthDate == "") {
          seterrorBirthDate("Inserisci la tua data di nascita");
        }
        if (userInfo1.phoneNumber == "") {
          seterrorPhoneNumber("Inserisci un numero di telefono");
        }
        if (userInfo1.specialization == "") {
          seterrorSpecialization("Seleziona la tua specializzazione");
        }
      }
    } else if (activeStep == 1) {
      if (userInfo2.city !== "" && userInfo2.CAP !== "") {
        if (clinic) {
          if (userInfo2.address !== "") {
            setActiveStep(activeStep + 1);
          } else {
            seterrorAddress("Indirizzo");
          }
        } else {
          setActiveStep(activeStep + 1);
        }
      } else {
        if (userInfo2.city == "") {
          seterrorCity("Città");
        }
        if (userInfo2.CAP == "") {
          seterrorCAP("CAP");
        }
        if (clinic) {
          if (userInfo2.address == "") {
            seterrorAddress("Indirizzo");
          }
        }
      }
    } else if (activeStep == 2) {
      let validEmail = false;
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (userInfo3.email == "") {
        seterroremail("Inserisci indirizzo email");
      } else if (reg.test(userInfo3.email) == false) {
        seterroremail("Indirizzo email non valido");
      } else {
        seterroremail("");
        validEmail = true;
      }
      if (userInfo3.password == "") {
        seterrorpassword("Inserisci una password");
      } else if (userInfo3.password.length < 8) {
        seterrorpassword("La password deve avere almeno 8 caratteri");
      } else {
        seterrorpassword("");
      }
      if (userInfo3.confpassword !== userInfo3.password) {
        seterrorconfpassword("Le password inserite non coincidono");
      } else {
        seterrorconfpassword("");
      }
      if (!userInfo3.termconditions)
      {
        seterrortermconditions("Per proseguire devi accettare i termini e le condizioni");
      }
      else
      {
        seterrortermconditions("");
      }
      if (
        userInfo3.confpassword == userInfo3.password &&
        userInfo3.email !== "" &&
        userInfo3.password !== "" &&
        userInfo3.password.length >= 8 &&
        validEmail === true &&
        userInfo3.termconditions
      ) {
        
        var vals = {
          name: userInfo1.name,
          surname: userInfo1.surname,
          email: userInfo3.email,
          password: userInfo3.password,
          mobile: userInfo1.phoneNumber,
          telephone: userInfo1.alternateNumber,
          Specialization: userInfo1.specialization[0].label,
          clinic_name: userInfo2.name,
          birth_city: userInfo2.city.label,
          business_address: userInfo2.address,
          zipcode: userInfo2.CAP,
          birth_date:userInfo1.birthDate,
          termconditions: userInfo3.termconditions
        };

        await dispatch(signUpAction(vals));
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  // if (localStorage.getItem("user") && localStorage.getItem("token")) {
  //   props.history.push("/dashboard");
  // }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Registry
            setUserInfo1={setUserInfo1}
            userInfo1={userInfo1}
            errorName={errorName}
            errorSurname={errorSurname}
            errorBirthDate={errorBirthDate}
            errorPhoneNumber={errorPhoneNumber}
            errorSpecialization={errorSpecialization}
            seterrorName={seterrorName}
            seterrorSurname={seterrorSurname}
            seterrorBirthDate={seterrorBirthDate}
            seterrorPhoneNumber={seterrorPhoneNumber}
            seterrorSpecialization={seterrorSpecialization}
          />
        );
      case 1:
        return (
          <WorkActivity
            online={online}
            clinic={clinic}
            setOnline={setOnline}
            setClinic={setClinic}
            userInfo2={userInfo2}
            setUserInfo2={setUserInfo2}
            errorCity={errorCity}
            seterrorCity={seterrorCity}
            errorAddress={errorAddress}
            seterrorAddress={seterrorAddress}
            errorCAP={errorCAP}
            seterrorCAP={seterrorCAP}
          />
        );
      case 2:
        return (
          <Credentials
            userInfo3={userInfo3}
            setUserInfo3={setUserInfo3}
            erroremail={erroremail}
            seterroremail={seterroremail}
            errorpassword={errorpassword}
            seterrorpassword={seterrorpassword}
            errorconfpassword={errorconfpassword}
            seterrorconfpassword={seterrorconfpassword}
            errortermconditions={errortermconditions}
            seterrortermconditions = {seterrortermconditions}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <img className="logo" 
            src={mainlogo} 
            alt="logo"/>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Registrazione Dottore
          </Typography>
          <br/>
          <br/>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <br/>
          <br/>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
              
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  <Typography variant="body1">
                    Hai già un account?{" "}
                    <Link href="/signin" style={{ color: "#1a5177" }}>
                      {" "}
                      Accedi.
                    </Link>
                  </Typography>
                  <div>
                    {activeStep !== 0 ? (
                      <Button
                        onClick={handleBack}
                        variant="contained"
                        disabled={activeStep === 0}
                        className={classes.Backbutton}
                      >
                        Indietro
                      </Button>
                    ) : null}

                    <Button
                      variant="contained"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Completa"
                        : "Avanti"}
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
