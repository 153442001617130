import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import useStyles from "../signin/SignInStyles";
import mainlogo from "../../logo-easydoctor.png";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" target="_blank" href="https://easydoctor.io/">
          easydoctor
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

export default function DownloadApp(props) {
    const classes = useStyles();
    const userReducer = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [email, setEmail] = useState("");
    const [erroremail, seterroremail] = useState("");

    return (
        
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          
          <div className={classes.paper}>
            <img className="logo" 
              src={mainlogo} 
              alt="logo"/>
            <Typography component="h1" variant="h5" gutterBottom>
                Per accedere al tuo profilo apri l'applicazione installata sul tuo smartphone
            </Typography>
            <a href="https://apps.apple.com/it/app/easydoctor/id1573931352" >App per IOS</a> 
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      );
}