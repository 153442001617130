import React, { useState,useEffect } from "react";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import Title from "../Title";
import Dashboard from "../Dashboard";
import { Copyright } from "../Calendar/Calendar";
import "./invite.css";
import { useHistory } from "react-router-dom";
import {
  Container,
  TableContainer,
  Paper,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ImageUploading from "react-images-uploading";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import UploadIcon from "../../../images/UploadIcon.svg";
import useStyles from "./InviteStyles";
import useDashboardStyles from "../DashboardStyles";
import useCalendarStyles from "../Calendar/CalendarStyles";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { sendInvitationLink } from "../../../actions/UserActions";
// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, name, shipTo, date, paymentMethod, amount };
}
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function Orders() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dashboardClasses = useDashboardStyles();
  const calendarClasses = useCalendarStyles();
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setrowsData] = useState([]);
  useEffect(async () => {
    axios
    .get(`${process.env.REACT_APP_API_URL}/api/getpatientdetails`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      let PatientArray = [];
      res.data.patientname.map((element,index)=>{
        console.log("element",element);
        PatientArray.push(createData(
          index+1,
          element.phone,
          element.name,
          element.email,
          element.birth_date,
        ))
      })
      setrowsData(PatientArray)
    })
    .catch((err) => {
      alert("some problem in featch paitent data");
    });
  }, []);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [errorName, seterrorName] = useState("");
  const [errorDate, seterrorDate] = useState("");
  const [errorTaxCode, seterrorTaxCode] = useState("");
  const [errorPhone, seterrorPhone] = useState("");
  const [errorEmail, seterrorEmail] = useState("");
  const [errorimg, seterrorimg] = useState("");
  const [inviteUser, setInviteUser] = useState({
    picture: "",
    name: "",
    date: "",
    taxCode: "",
    phone: "",
    email: "",
  });
  const onChangeimage = (imageList, addUpdateIndex) => {
    const files = [];
    console.log(imageList, addUpdateIndex);
    imageList.forEach((image) => {
      files.push(image.file);
    });
    setInviteUser({
      ...inviteUser,
      picture: [...imageList],
    });

    // console.log("imageList", this.state.pictures);
  };
  const handleClose = () => {
    setOpen(false);
    seterrorName("");
    seterrorDate("");
    seterrorTaxCode("");
    seterrorPhone("");
    seterrorEmail("");
    setInviteUser({
      name: "",
      date: "",
      taxCode: "",
      phone: "",
      email: "",
    });
  };
  const handleChange = (e) => {
    setInviteUser({
      ...inviteUser,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    let validEmail = false;
    if (inviteUser.name == "") {
      seterrorName("Please enter user name");
    } else {
      seterrorName("");
    }
    if (inviteUser.date == "") {
      seterrorDate("Please select date");
    } else {
      seterrorDate("");
    }
    if (inviteUser.taxCode == "") {
      seterrorTaxCode("Please enter fiscal code");
    } else {
      seterrorTaxCode("");
    }
    if (inviteUser.phone == "") {
      seterrorPhone("Please enter phone number");
    } else {
      seterrorPhone("");
    }
    if (inviteUser.email == "") {
      seterrorEmail("Please enter user's email");
    } else if (reg.test(inviteUser.email) == false) {
      seterrorEmail("Invalid email address");
    } else {
      validEmail = true;
      seterrorEmail("");
    }

    if (
      inviteUser.name == "" &&
      inviteUser.date == "" &&
      inviteUser.taxCode == "" &&
      inviteUser.phone == "" &&
      inviteUser.email == "" &&
      validEmail == true
    ) {
      alert("error");
    } else {
      var link = `${process.env.REACT_APP_FRONT_URL}/acceptRequest?name=${inviteUser.name
        }&birth_date=${inviteUser.date}&phone=${inviteUser.phone}&email=${inviteUser.email
        }&id=${JSON.parse(localStorage.getItem("user")).id}`;
      
      await dispatch(
        sendInvitationLink({ patient: inviteUser, link: link })
      );
      handleClose();
    }
  };
  let tableBody;
  let data;
  // if (rowsPerPage > 0) {
  data = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // } else {
  //   data = rows;
  // }
  let history = useHistory();
  console.log("rows-==--=", data);
  tableBody = data.map((row, i) => (
    <TableRow key={i}>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.shipTo}</TableCell>
      <TableCell>{row.paymentMethod}</TableCell>
      <TableCell>{row.date}</TableCell>
      
      <TableCell><Link onClick={() => {
              history.push("/clinicaldata");
            }}>Cartella clinica</Link></TableCell>
    </TableRow>
  ));

  return (
    <div className={dashboardClasses.root}>
      <Dashboard />
      {console.log("page-==--=", page)}
      {console.log("rowsPerPage-==--=", rowsPerPage)}
      {console.log(
        "rows-==--=",
        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      )}

      <main className={dashboardClasses.content}>
        <div className={dashboardClasses.appBarSpacer} />
        <Container maxWidth="lg" className={dashboardClasses.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper>
                <Container>
                  <div className={classes.container}>
                    {/* <Title>Recent Orders</Title> */}
                    <Grid container className={calendarClasses.grid}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        className={calendarClasses.gridItem}
                      >
                        <div>
                          <Button
                            onClick={() => {
                              setOpen(true);
                            }}
                            color="primary"
                            variant="contained"
                            className={classes.button}
                          >
                            Nuovo Paziente
                          </Button>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        className={calendarClasses.header}
                      >
                        <TextField
                          id="outlined-search"
                          label="Cerca Paziente"
                          type="search"
                          variant="outlined"
                          className={classes.searchBar}
                        />
                      </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            {/* <TableCell className={classes.tableHeadCell}>
                              Num
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Nome Cognome
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Num. Telefono
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Etá
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Ultima visita
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Registrazione
                            </TableCell> */}
                            <TableCell className={classes.tableHeadCell}>
                              Num
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Nome Cognome
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Patient Email
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Data di nascita
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Numero di Cellulare
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                              Cartella clinica
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableBody}

                          {/* {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                        </TableBody>

                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                              ]}
                              colSpan={3}
                              count={rows.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                              }}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>{" "}
                    <Dialog
                      fullWidth={true}
                      maxWidth={"sm"}
                      scroll="body"
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="form-dialog-title  "
                    >
                      <DialogTitle id="form-dialog-title">
                        Nuovo Paziente
                      </DialogTitle>
                      <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              autoFocus
                              value={inviteUser.name}
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.value == "") {
                                  seterrorName("Please enter user name");
                                } else {
                                  seterrorName("");
                                }
                              }}
                              name="name"
                              label="Nome e Cognome"
                              type="text"
                              fullWidth
                              required
                              variant="outlined"
                            />
                            {errorName != "" ? (
                              <p style={{ color: "red", marginTop: "1px" }}>
                                {errorName}
                              </p>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={inviteUser.date}
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.value == "") {
                                  seterrorDate("Please select date");
                                } else {
                                  seterrorDate("");
                                }
                              }}
                              name="date"
                              label="Data di nascita"
                              type="date"
                              fullWidth
                              required
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            {errorDate != "" ? (
                              <p style={{ color: "red", marginTop: "1px" }}>
                                {errorDate}
                              </p>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={inviteUser.taxCode}
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.value == "") {
                                  seterrorTaxCode("Please enter fiscal code");
                                } else {
                                  seterrorTaxCode("");
                                }
                              }}
                              name="taxCode"
                              label="Codice Fiscale"
                              type="text"
                              fullWidth
                              required
                              variant="outlined"
                            />{" "}
                            {errorTaxCode != "" ? (
                              <p style={{ color: "red", marginTop: "1px" }}>
                                {errorTaxCode}
                              </p>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={inviteUser.phone}
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.value == "") {
                                  seterrorPhone("Please enter phone number");
                                } else {
                                  seterrorPhone("");
                                }
                              }}
                              name="phone"
                              label="Numero di Cellulare"
                              type="number"
                              fullWidth
                              required
                              variant="outlined"
                            />{" "}
                            {errorPhone != "" ? (
                              <p style={{ color: "red", marginTop: "1px" }}>
                                {errorPhone}
                              </p>
                            ) : null}
                          </Grid>{" "}
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={inviteUser.email}
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.value == "") {
                                  seterrorEmail("Please enter user's email");
                                } else if (reg.test(e.target.value) == false) {
                                  seterrorEmail("Invalid email address");
                                } else {
                                  seterrorEmail("");
                                }
                              }}
                              name="email"
                              label="Email"
                              type="email"
                              fullWidth
                              required
                              variant="outlined"
                            />
                            {errorEmail != "" ? (
                              <p style={{ color: "red", marginTop: "1px" }}>
                                {errorEmail}
                              </p>
                            ) : null}
                          </Grid>
                        </Grid>
                        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleClose}
                          color="primary"
                          variant="contained"
                          className={classes.Backbutton}
                        >
                          Cancel
                        </Button>

                        <Button
                          onClick={handleSubmit}
                          color="primary"
                          variant="contained"
                          className={classes.button}
                        >
                          Invia Richiesta
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </Container>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
