import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 1000,
    //   marginLeft: "auto",
    //   marginRight: "auto",
    // },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0",
      marginRight: "0",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      "&div": { justifyContent: "center" },
    },
    marginTop: theme.spacing(3),
  },
  button: {
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    background: "#2E9B92",
    borderRadius: "36px",
    color: "white",

    "&:hover": {
      background: "#2E9B92",
      color: "white",
    },
  },
  Backbutton: {
    borderRadius: "36px",
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    background: "#fff",
    color: "#2E9B92",
    border: "1px solid #2E9B92",
  },
}));

export default useStyles;
