import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import { TextField, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PersonOutlineOutlined from "@material-ui/icons/PersonOutlineOutlined";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useStyles from "./RegistryStyles";
import { useDispatch, useSelector } from "react-redux";
import { allSpecializations } from "../../../actions/UserActions";
import mainlogo from "../../../logo-easydoctor.png";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// let names = [
//   { label: "Oliver Hansen" },
//   { label: "Van Henry" },
//   { label: "April Tucker" },
//   { label: "Ralph Hubbard" },
//   { label: "Omar Alexander" },
//   { label: "Carlos Abbott" },
//   { label: "Miriam Wagner" },
//   { label: "Bradley Wilkerson" },
//   { label: "Virginia Andrews" },
//   { label: "Kelly Snyder" },
// ];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function AddressForm(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const curDate = new Date();
  const phoneRef = useRef();
  const specialization = useRef();
  const [addnewTextBox, setAddnewTextBox] = useState(false);
  const [newSpecialization, setNewSpecialization] = useState("");
  const [allSpecialization, setallSpecialization] = useState([]);
  const userReducer = useSelector((state) => {
    return state.user;
  });

  useEffect(async () => {
    await dispatch(allSpecializations());
  }, []);

  useEffect(async () => {

    if (userReducer) {
      let result = userReducer.specialization.data.map((o) => ({
        label: o.name,
      }));
     
      setallSpecialization(result);
    }
  }, [userReducer]);

  const handleChange = (event) => {
    if (
      event.target.name == "phoneNumber" ||
      event.target.name == "alternateNumber"
    ) {
      if (parseInt(event.target.value) != event.target.value) {
        phoneRef.current.value = "";
        props.setUserInfo1({
          ...props.userInfo1,
          [event.target.name]: "",
        });
      } else {
        phoneRef.current.value = event.target.value;

        props.setUserInfo1({
          ...props.userInfo1,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      props.setUserInfo1({
        ...props.userInfo1,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleDelete = (event, value) => {
    let index = props.userInfo1.specialization.indexOf(value);
    let newArr = props.userInfo1.specialization;
    if (index > -1) {
      newArr.splice(index, 1);
    }
    if (newArr.length == 0) {
      props.seterrorSpecialization("Seleziona specializzazione");
    } else {
      props.seterrorSpecialization("");
    }
    props.setUserInfo1({
      ...props.userInfo1,
      specialization: [...newArr],
    });
  };
  const handleAddNew = () => {
    setAddnewTextBox(true);
  };
  return (
    <React.Fragment>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} className={classes.icon}>
        
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="firstName"
            name="name"
            label="Name"
            variant="outlined"
            value={props.userInfo1.name}
            // variant="filled"

            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                props.seterrorName("Please enter your name");
              } else {
                props.seterrorName("");
              }
            }}
            fullWidth
            autoComplete="given-name"
          />
          {props.errorName != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>{props.errorName}</p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            variant="outlined"
            id="lastName"
            name="surname"
            label="Cognome"
            // variant="filled"
            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                props.seterrorSurname("Please enter your surname");
              } else {
                props.seterrorSurname("");
              }
            }}
            value={props.userInfo1.surname}
            fullWidth
            autoComplete="family-name"
          />{" "}
          {props.errorSurname != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>
              {props.errorSurname}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            type="date"
            variant="outlined"
            required
            id="address1"
            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                props.seterrorBirthDate("Please select your birth date");
              } else {
                props.seterrorBirthDate("");
              }
            }}
            value={props.userInfo1.birthDate}
            name="birthDate"
            defaultValue={curDate}
            label="Data di nascita"
            // variant="filled"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />{" "}
          {props.errorBirthDate != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>
              {props.errorBirthDate}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            variant="outlined"
            id="address2"
            inputRef={phoneRef}
            name="phoneNumber"
            onChange={(e) => {
              handleChange(e);
              if (phoneRef.current.value == "") {
                props.seterrorPhoneNumber("Inserisci il tuo numero di telefono");
              } else {
                props.seterrorPhoneNumber("");
              }
            }}
            label="Numero di cellulare"
            // variant="filled"
            fullWidth
            value={props.userInfo1.phoneNumber}
          />{" "}
          {props.errorPhoneNumber != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>
              {props.errorPhoneNumber}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            id="city"
            name="alternateNumber"
            onChange={(e) => {
              handleChange(e);
            }}
            label="Altro numero di telefono"
            fullWidth
            value={props.userInfo1.alternateNumber}

            // variant="filled"
          />{" "}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={allSpecialization}
            getOptionLabel={(allSpecialization) => allSpecialization.label}
            filterSelectedOptions
            ref={specialization}
            value={props.userInfo1.specialization}
            name="specialization"
            onChange={(e, newValue) => {
              props.setUserInfo1({
                ...props.userInfo1,
                specialization: newValue,
              });

              if (newValue.length == 0) {
                props.seterrorSpecialization(
                  "Seleziona la tua specializzazione"
                );
              } else {
                props.seterrorSpecialization("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Specializzazioni"
                // placeholder="Specializzazioni"
              />
            )}
          />
          {/* <FormControl required variant="outlined" fullWidth>
            <InputLabel>Specializzazioni</InputLabel>
            <Select
              name="specialization"
              multiple
              value={props.userInfo1.specialization}
              onChange={(e) => {
                handleChange(e);

                if (e.target.value.length == 0) {
                  props.seterrorSpecialization(
                    "Please select your specialization"
                  );
                } else {
                  props.seterrorSpecialization("");
                }
              }}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value, i) => (
                    <Chip
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={(e) => handleDelete(e, value)}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, props.userInfo1.specialization, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>{" "}
           */}
          {props.errorSpecialization != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>
              {props.errorSpecialization}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} className={classes.buttons}>
          {addnewTextBox ? (
            <>
              {" "}
              <TextField
                required
                id="address2"
                name="phoneNumber"
                onChange={(e) => {
                  setNewSpecialization(e.target.value);
                }}
                label="Aggiungi specializzazione"
                variant="outlined"
                className={classes.addNew}
                value={newSpecialization}
              />
              <div style={{ margin: "10px" }}>
                <Button
                  variant="contained"
                  className={classes.addNew}
                  onClick={() => {
                    if (newSpecialization != "") {
                      
                      // test = [
                      //   ...allSpecialization,
                      //   { label: newSpecialization },
                      // ];
                      setallSpecialization([
                        ...allSpecialization,
                        { label: newSpecialization },
                      ]);

                      props.setUserInfo1({
                        ...props.userInfo1,
                        specialization: [
                          ...props.userInfo1.specialization,
                          { label: newSpecialization },
                        ],
                      });
                      setNewSpecialization("");
                      setAddnewTextBox(false);
                    } else {
                      setAddnewTextBox(false);
                    }
                  }}
                >
                  Conferma
                </Button>
                <Button
                  className={classes.addNew}
                  variant="contained"
                  onClick={() => setAddnewTextBox(false)}
                >
                  Indietro
                </Button>
              </div>
            </>
          ) : (
            <Button variant="contained" onClick={handleAddNew}>
              Aggiungi specializzazione
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
