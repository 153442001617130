import {
  SIGNIN_SUCCESS,
  ALL_SPECIALIZATIONS_SUCCESS,
  SIGNIN_ERROR,
  LOGOUT,
  ALL_CITIES_SUCCESS,
  USER_DETAILS_UPDATE
} from "../actions/type";

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNIN_SUCCESS:
      if (action.payload.user) {
        localStorage.setItem("user", JSON.stringify(action.payload.user));
        localStorage.setItem("token", action.payload.access_token);
        return {
          ...state,
          user: action.payload.user,
          token: action.payload.access_token,
        };
      } else {
        return {
          message: action.payload,
        };
      }
      break;

    case ALL_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        specialization: action.payload,
      };

    case USER_DETAILS_UPDATE:
      return {
        ...state,
        userDetails: action.payload,
      };

    case ALL_CITIES_SUCCESS:
      return {
        ...state,
        city: action.payload,
      };

    case SIGNIN_ERROR:
      return {
        message: "Error signing in",
      };

    case LOGOUT: {
      return {};
    }

    default:
      return state;
  }
}
