import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import useStyles from "./ForgotPasswordStyles";
import axios from "axios";
import swal from "sweetalert";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ForgotPassword(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [erroremail, seterroremail] = useState("");
  const [password, setPassword] = useState("");
  const [errorpassword, seterrorpassword] = useState("");
  const [isloading, setIsloading] = useState(false);


  const handleChange = (e) => {
    if (e.target.name == "email") {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };
  const handleSubmit = () => {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let validEmail = false;
    if (email == "") {
      seterroremail("Please enter your email");
    } else if (reg.test(email) == false) {
      seterroremail("Invalid email address");
    } else {
      seterroremail("");
      validEmail = true;
    }
    // if (password == "") {
    //   seterrorpassword("Please enter your password");
    // } else if (password.length < 8) {
    //   seterrorpassword("Password must have atleast 8 characters");
    // } else {
    //   seterrorpassword("");
    // }
    if (
      email != "" &&
      validEmail == true
      // &&
      // password != "" &&
      // password.length >= 8
    ) {
      setIsloading(true)
      let data = { email: email }
      axios
      .post(`${process.env.REACT_APP_API_URL}/api/passwordrecovery`,data)
      .then((res) => {
        console.log("res",res);
        if(res.data.success===1){
          swal("success", res.data.message, "success");
        }
        else{
          swal("Ooops!", res.data.message, "error");
        }
        setIsloading(false)
      })
      .catch((err) => {
        alert("some problem in featch paitent data");
      });
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Forgot Password
        </Typography>
        <Typography
          variant="body"
          style={{ textAlign: "center", color: "black", marginTop: "1rem" }}
          gutterBottom
        >
          Enter your email and you'll recieve an email with a code that you can
          use to update your password.
        </Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={email}
            id="email"
            onChange={(e) => {
              handleChange(e);
              var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
              if (e.target.value == "") {
                seterroremail("Please enter your email");
              } else if (reg.test(e.target.value) == false) {
                seterroremail("Invalid email address");
              } else {
                seterroremail("");
              }
            }}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {erroremail != "" ? (
            <p style={{ color: "red", margin: 0 }}>{erroremail}</p>
          ) : null}
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                seterrorpassword("Please enter your password");
              } else if (e.target.value.length < 8) {
                seterrorpassword("Password must have atleast 8 characters");
              } else {
                seterrorpassword("");
              }
            }}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />{" "}
          {errorpassword != "" ? (
            <p style={{ color: "red", margin: 0 }}>{errorpassword}</p>
          ) : null} */}
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            {
              isloading ?
                <CircularProgress size="25px" style={{ color: "white" }} /> :
                "Reset Password"
            }

          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="/signin" variant="body2" className={classes.link}>
                Login to your account
              </Link>
            </Grid> */}
            <Grid item>
              <Link href="/signup" variant="body2" className={classes.link}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
