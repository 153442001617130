import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tableHead: {
    background: "rgba(26,81,119,0.15)",
  },
  tableHeadCell: {
    fontWeight: "600",
  },
  searchBar: {
    borderRadius: "20px",
  },
  button: {
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    background: "#2E9B92",
    borderRadius: "36px",
    color: "white",

    "&:hover": {
      background: "#2E9B92",
      color: "white",
    },
  },
  Backbutton: {
    margin: "0 7px",
    borderRadius: "36px",
    background: "#fff",
    color: "#2E9B92",
    border: "0.25px solid #2E9B92",
    "&:hover": {
      color: "#2E9B92",
      background: "white",
    },
  },
}));

export default useStyles;
