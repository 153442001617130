import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import useStyles from "../signin/SignInStyles";
import mainlogo from "../../logo-easydoctor.png";
import { verifyProfile } from "../../actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" target="_blank" href="https://easydoctor.io/">
          easydoctor
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

export default function Verification(props) {
    const classes = useStyles();
    const userReducer = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [email, setEmail] = useState("");
    const [erroremail, seterroremail] = useState("");

    const handleChange = (e) => {
        if (e.target.name == "email") {
          setEmail(e.target.value);
        } 
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let validEmail = false;
        if (email == "") {
          seterroremail("Inserisci il tuo indirizzo email");
        } else if (reg.test(email) == false) {
          seterroremail("L'indirizzo email inserito non è valido");
        } else {
          seterroremail("");
          validEmail = true;
        }
        
        if (
          email != "" &&
          validEmail == true 
        ) {
          await dispatch(verifyProfile(email));
        }
      };
      
    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img className="logo" 
              src={mainlogo} 
              alt="logo"/>
            <Typography component="h1" variant="h5" gutterBottom>
                Se non hai ricevuto l'email di attivazione
                verifica nello spam oppure reinserisci il tuo indirizzo email
            </Typography>
            
            {alert ? (
              <Alert
                onClose={() => {
                  setAlert(false);
                }}
                severity={
                  alertMsg == "Signed in successfully" ? "success" : "error"
                }
                className={classes.alert}
              >
                {alertMsg}
              </Alert>
            ) : null}
            <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                value={email}
                onChange={(e) => {
                  handleChange(e);
                  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                  if (e.target.value == "") {
                    seterroremail("Inserisci il tuo indirizzo email");
                  } else if (reg.test(e.target.value) == false) {
                    seterroremail("L'indirizzo email non è valido");
                  } else {
                    seterroremail("");
                  }
                }}
                label="Indirizzo email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              {erroremail != "" ? (
                <p style={{ color: "red", margin: 0 }}>{erroremail}</p>
              ) : null}
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => handleSubmit(e)}
              >
                Invia email di attivazione
              </Button>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      );
}