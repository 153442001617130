import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import Title from "../Title";
import useStyles from "../DashboardStyles";
import meetingStyles from "./MeetingStyles";
import Dashboard from "../Dashboard";
import ViewListIcon from '@material-ui/icons/ViewList'
import AddIcon from '@material-ui/icons/Add';
import { Box } from "@material-ui/core";
import { Container, Paper, Grid, TextField, Typography, Button, TextareaAutosize } from "@material-ui/core";
import './Meeting.css'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getRecordsOfEvent,
  insertRecordsOfEvents,
  updateRoomlinkForEvent
} from "../../../actions/CalendarActions";
import swal from "sweetalert";



export default function Meeting(props) {
  const dispatch = useDispatch();
  const calendarReducer = useSelector((state) => {
    return state.calendar;
  });
  const classes = useStyles();
  const meetingClasses = meetingStyles();
  const [notesArray, setallNotesArray] = useState([]);
  const [showArray, setShowArray] = useState([]);

  const [isForm, setIsForm] = useState(true);

  const [reason, setReason] = useState("");
  const [anamnesis, setAnamnesis] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [conclusions, setConclusions] = useState("");
  const [roomLink, setRoomLink] = useState("");
  const [roomToken, setRoomToken] = useState("");
  const [isJoin, setIsJoin] = useState(false);

  const [errorReason, setErrorReason] = useState(false);
  const [errorAnamnesis, setErrorAnamnesis] = useState(false);
  const [errorDiagnosis, setErrorDiagnosis] = useState(false);
  const [errorSuggestion, setErrorSuggestion] = useState(false);
  const [errorConclusions, setErrorConclusions] = useState(false);

  const [apiCall, setApiCall] = useState(false);


  useEffect(async () => {
    await dispatch(getRecordsOfEvent(props.location.state.eventId));
  }, []);

  useEffect(async () => {
    if (calendarReducer) {
      if (calendarReducer.eventRecord !== undefined) {
        console.log("calendarReducer", calendarReducer.eventRecord);
        if (calendarReducer.eventRecord.roomToken.length > 0) {
          if (calendarReducer.eventRecord.roomToken[0].room_token !== null) {
            setApiCall(true)
            setRoomToken(calendarReducer.eventRecord.roomToken[0].room_token)
          }else{
            setRoomToken("")
          }
        }
        if (calendarReducer.eventRecord.records.length > 0) {
          setallNotesArray(calendarReducer.eventRecord.records)
        }
      }
    }
  }, [calendarReducer]);

  const handleSaveEvent = async () => {
    var temp = 0
    if (reason === "") {
      setErrorReason(true)
      temp = 1
    }
    if (anamnesis === "") {
      setErrorAnamnesis(true)
      temp = 1
    }
    if (diagnosis === "") {
      setErrorDiagnosis(true)
      temp = 1
    }
    if (suggestion === "") {
      setErrorSuggestion(true)
      temp = 1
    }
    if (conclusions === "") {
      setErrorConclusions(true)
      temp = 1
    }
    if (temp === 0) {
      var values = {
        calendar_id: props.location.state.eventId,
        reason: reason,
        anamnesis: anamnesis,
        diagnosis: diagnosis,
        suggestion: suggestion,
        conclusions: conclusions
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/storeeventdetails`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          swal("success", "Record inserted", "success");
          setallNotesArray([
            ...notesArray,
            {
              calendar_id: props.location.state.eventId,
              reason: reason,
              anamnesis: anamnesis,
              diagnosis: diagnosis,
              suggestion: suggestion,
              conclusions: conclusions
            },
          ]);
          setReason("")
          setAnamnesis("")
          setDiagnosis("")
          setSuggestion("")
          setConclusions("")
        })
        .catch((err) => {
          alert("some problem in insert calendar");
        });
    }
  }
  const createNewRoom = async () => {
    console.log("API caclll");
    const url = 'https://api.daily.co/v1/rooms';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_DAILY}` },
      body: JSON.stringify({ properties: { enable_network_ui: false } })
    };

    fetch(url, options)
      .then(response => response.json())
      .then(json => {
        console.log("response", json)
        setRoomLink(json.url)
        fetch('https://api.daily.co/v1/meeting-tokens', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_DAILY}` },
          body: JSON.stringify({ properties: { room_name: json.name } })
        })
          .then(response => response.json())
          .then(async (response) => {
            setRoomToken(response.token)
            await dispatch(updateRoomlinkForEvent({ calendarid: props.location.state.eventId, roomToken: response.token }))
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error('error:' + err));
  }

  const getDetailsRoom = async () => {
    console.log("API caclll");
    const options = { method: 'GET', headers: { Authorization: `Bearer ${process.env.REACT_APP_DAILY}` } };

    fetch(`https://api.daily.co/v1/meeting-tokens/${roomToken}`, options)
      .then(response => response.json())
      .then(response => {
        setIsJoin(true)
        setRoomLink(`${process.env.REACT_APP_DAILY_HOST}/${response.room_name}`)
        console.log("token response", response)
      })
      .catch(err => console.error(err));
  }

  const deleteRoom = async () => {
      const options = { method: 'DELETE', headers: { Authorization: `Bearer ${process.env.REACT_APP_DAILY}` } };
      console.log("roomLink.split('/').pop()", roomLink.split('/').pop());
      setIsJoin(false)
      setRoomLink("")
      setRoomToken("")
      fetch(`https://api.daily.co/v1/rooms/${roomLink.split('/').pop()}`, options)
        .then(response => response.json())
        .then(async(response) => {
          await dispatch(updateRoomlinkForEvent({ calendarid: props.location.state.eventId, roomToken: null }))
          console.log(response)
        })
        .catch(err => console.error(err));
  }

  

  return (
    <div className={classes.root}>
      <Dashboard />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <div className="sub_part">
                <Typography component="h1" variant="h5" className="header_title" >
                  Incontro
                </Typography>
                <hr></hr>
                  {roomToken === "" ?
                    <Button
                      variant="contained"
                      onClick={createNewRoom}
                      className={meetingClasses.button}
                    >
                      Prepara il Videoconsulto
                    </Button>
                    : ""
                  }
                {roomToken !== "" ?
                  <>
                    {/* <TextField
                      readOnly
                      variant="outlined"
                      type="text"
                      name="reason"
                      value={roomToken}
                      label="Meeting Token"
                      fullWidth
                      className={meetingClasses.textField}
                      style={{ marginTop: "10px" }}
                    />{" "} */}
                    {!isJoin ?
                      <Button
                        variant="contained"
                        onClick={getDetailsRoom}
                        className={meetingClasses.button}
                      >
                        Join Meeting
                    </Button>
                      : ""
                    }
                  </>
                  : <></>
                }
                {isJoin ?
                  <>
                    <iframe className="video_frame"
                      src={roomLink} allow="camera;microphone">
                    </iframe>
                    <div style={{ display: "inline-flex", marginTop: "10px" }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setIsJoin(false)
                        }}
                        className={meetingClasses.button}
                      >
                        Leave
                    </Button>
                      <Button
                        variant="contained"
                        // onClick={() => setIsJoin(true)}
                        onClick={deleteRoom}
                        className={meetingClasses.button}
                        style={{ marginLeft: "5px" }}
                      >
                        Remove room 
                      </Button>  
                    </div>
                    <div style={{clear:"both",width:"100%"}}>
                        Invita il paziente a partecipare <br/>
                        {roomLink}
                    </div>
                  </> : ""
                }
              </div>
            </Grid>
            <Grid item xs={12} md={6} >
              <div className="sub_part">

                {isForm ?
                  <>
                    <div className="list_notes" style={{color:"#1a5175"}} onClick={() => {
                      setShowArray(notesArray.reverse())
                      setIsForm(!isForm)
                    }}>
                      <ViewListIcon />
                      <Typography component="p" variant="body1" style={{ marginBottom: "10px" ,marginLeft:"5px" }}>
                        Storico cartelle cliniche
                      </Typography>
                    </div>
                    <hr></hr>
                    <TextField
                      required
                      variant="outlined"
                      type="text"
                      name="reason"
                      value={reason}
                      label="Motivo visita"
                      fullWidth
                      className="textfield_css"
                      error={errorReason}
                      helperText={errorReason ? 'Empty field!' : ' '}
                      onChange={(e) => {
                        setReason(e.target.value)
                        if (e.target.value == "") {
                          setErrorReason(true)
                        } else {
                          setErrorReason(false)
                        }
                      }
                      }
                    />{" "}

                    <TextField
                      required
                      variant="outlined"
                      type="text"
                      value={anamnesis}
                      name="anamnesis"
                      label="Anamnesi"
                      fullWidth
                      multiline
                      rows="5"
                      className={meetingClasses.textField}
                      error={errorAnamnesis}
                      helperText={errorAnamnesis ? 'Empty field!' : ' '}
                      onChange={(e) => {
                        setAnamnesis(e.target.value)
                        if (e.target.value == "") {
                          setErrorAnamnesis(true)
                        } else {
                          setErrorAnamnesis(false)
                        }
                      }
                      }

                    />{" "}

                    <TextField
                      required
                      variant="outlined"
                      type="text"
                      value={diagnosis}
                      name="diagnosi"
                      label="Diagnosi"
                      fullWidth
                      multiline
                      rows="5"
                      className={meetingClasses.textField}
                      error={errorDiagnosis}
                      helperText={errorDiagnosis ? 'Empty field!' : ' '}
                      onChange={(e) => {
                        setDiagnosis(e.target.value)
                        if (e.target.value == "") {
                          setErrorDiagnosis(true)
                        } else {
                          setErrorDiagnosis(false)
                        }
                      }
                      }
                    />{" "}

                    <TextField
                      error={errorSuggestion}
                      helperText={errorSuggestion ? 'Empty field!' : ' '}
                      required
                      variant="outlined"
                      value={suggestion}
                      type="text"
                      name="suggestion"
                      label="Suggerimento Terapeutico"
                      fullWidth
                      multiline
                      rows="5"
                      className={meetingClasses.textField}
                      onChange={(e) => {
                        setSuggestion(e.target.value)
                        if (e.target.value == "") {
                          setErrorSuggestion(true)
                        } else {
                          setErrorSuggestion(false)
                        }
                      }
                      }
                    />{" "}

                    <TextField
                      required
                      variant="outlined"
                      type="text"
                      name="conclusions"
                      value={conclusions}
                      label="Conclusioni/indicazioni"
                      fullWidth
                      multiline
                      rows="5"
                      className={meetingClasses.textField}
                      error={errorConclusions}
                      helperText={errorConclusions ? 'Empty field!' : ' '}
                      onChange={(e) => {
                        setConclusions(e.target.value)
                        if (e.target.value == "") {
                          setErrorConclusions(true)
                        } else {
                          setErrorConclusions(false)
                        }
                      }
                      }
                    />{" "}

                    <Button
                      variant="contained"
                      onClick={handleSaveEvent}
                      className={meetingClasses.button}
                    >
                      Salva e invia referto
                </Button>
                  </> :
                  <>
                    <div className="list_notes" style={{color:"#1a5175"}} onClick={() => { setIsForm(!isForm) }}>
                      <AddIcon />
                      <Typography component="p" variant="body1" style={{ marginBottom: "10px" }}>
                        Aggiungi nuovi record
                        </Typography>
                    </div>
                    <hr></hr>
                    {
                      showArray.length > 0 ?
                        showArray.map((element) => {
                          return <div className="details_section" style={{ marginTop: "15px" }}>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Motivo visita</div>:<div style={{whiteSpace:"break-spaces"}}> {element.reason} </div>
                            </Typography>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Anamnesi</div>:{ }<div style={{whiteSpace:"break-spaces"}}> {element.anamnesis} </div>
                            </Typography>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Diagnosi</div>:<div style={{whiteSpace:"break-spaces"}}>{element.diagnosis} </div>
                            </Typography>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Suggerimento Terapeutico</div>:<div style={{whiteSpace:"break-spaces"}}> {element.suggestion} </div>
                            </Typography>
                            <Typography component="p" variant="body1" className={meetingClasses.recordText}>
                              <div className="record_title">Conclusioni/indicazioni</div>:<div style={{whiteSpace:"break-spaces"}}> {element.conclusions}</div>
                            </Typography>
                          </div>
                        }) :
                        <Typography component="p" variant="body1" style={{ textAlign: "center" }}>
                          nessun record trovato
                    </Typography>
                    }
                  </>
                }

              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
