import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Title from "../Title";
import Dashboard from "../Dashboard";
import { Copyright } from "../Calendar/Calendar";
import "./UpdateUser.css";
import {
  Container,
  TableContainer,
  Paper,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ImageUploading from "react-images-uploading";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import UploadIcon from "../../../images/UploadIcon.svg";
import useStyles from "./UpdateUserStyles";
import useDashboardStyles from "../DashboardStyles";
import useCalendarStyles from "../Calendar/CalendarStyles";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { allSpecializations, allCities, userDetailsForUpdate ,updateUserDetails} from "../../../actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { sendDeleteLink } from "../../../actions/UserActions";

export default function Orders() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dashboardClasses = useDashboardStyles();
  // const allCity = [{ id: 1, label: "cty1" }, { id: 2, label: "cty2" }, { id: 3, label: "cty4" }]
  const [allCity, setallCity] = useState([]);
  // const allSpecialization = [{ id: 1, label: "ctdczzx1" }, { id: 2, label: "ctcxzxcy2" }, { id: 3, label: "ctczxcy4" }]
  const [allSpecialization, setallSpecialization] = useState([]);
  const userReducer = useSelector((state) => {
    return state.user;
  });

  const [userInfo, setUserInfo] = React.useState({
    name: "",
    surname: "",
    birthDate: "",
    phoneNumber: "",
    alternateNumber: "",
    specialization: "",
    clinicName: "",
    city: "",
    address: "",
    CAP: "",
  });
  const [nameError, setNameError] = React.useState(false)
  const [birthDateError, setBirthDateError] = React.useState(false)
  const [phoneNumberError, setPhoneNumberError] = React.useState(false)
  // const [specializationError, setSpecializationError] = React.useState(false)

  useEffect(async () => {
    await dispatch(allSpecializations());
    await dispatch(allCities());
    await dispatch(userDetailsForUpdate());

  }, []);

  useEffect(async () => {
    if (userReducer && userReducer.specialization) {
      let result = userReducer.specialization.data.map((o) => ({
        label: o.name,
      }));
      console.log(result);
      setallSpecialization(result);
    }
    if (userReducer && userReducer.city) {
      console.log("city state inside use effect...", userReducer.city.data);
      let result = userReducer.city.data.map((o) => ({
        label: o.name,
      }));
      console.log(result);
      setallCity(result);
    }
    if (userReducer && userReducer.userDetails) {
      console.log("userReducer.userDetails...", userReducer.userDetails[0]);
      setUserInfo({
        name: userReducer.userDetails[0].name,
        surname: userReducer.userDetails[0].surname,
        birthDate: userReducer.userDetails[0].birth_date,
        phoneNumber: userReducer.userDetails[0].mobile,
        alternateNumber: userReducer.userDetails[0].telephone,
        specialization: {label: userReducer.userDetails[0].Specialization },
        clinicName: userReducer.userDetails[0].clinic_name,
        city: {label:userReducer.userDetails[0].city},
        address: userReducer.userDetails[0].address,
        CAP: userReducer.userDetails[0].zip_code,
      })
      // let result = userReducer.city.data.map((o) => ({
      //   label: o.name,
      // }));
      // console.log(result);
      // setallCity(result);
    }
  }, [userReducer]);

  const handleChange = (event) => {
    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleDelete = async (e) => {
    await dispatch(
      sendDeleteLink({})
    );
  }

  const handleUpdate = async() => {
    var temp = 0
    console.log("handle update");
    console.log("username", userInfo.name);
    if (userInfo.name === "") {
      setNameError(true)
      temp = 1
    }
    if (userInfo.birthDate === "") {
      setBirthDateError(true)
      temp = 1
    }
    if (userInfo.phoneNumber === "") {
      setPhoneNumberError(true)
      temp = 1
    }
    // if (userInfo.specialization === "") {
    //   setSpecializationError(true)
    //   temp = 1
    // }
    if(temp !== 1){
      var vals = {
        name: userInfo.name,
        surname: userInfo.surname,
        birth_date: userInfo.birthDate,
        mobile: userInfo.phoneNumber,
        telephone: userInfo.alternateNumber,
        Specialization: userInfo.specialization.label,
        clinic: userInfo.clinicName,
        city: userInfo.city.label,
        address: userInfo.address,
        zip_code: userInfo.CAP,
      };
      await dispatch(updateUserDetails(vals));
    }
  }
  return (
    <div className={dashboardClasses.root}>
      {console.log("data", userInfo)}
      <Dashboard />
      <main className={dashboardClasses.content}>
        <div className={dashboardClasses.appBarSpacer} />
        <Container maxWidth="lg" className={dashboardClasses.container}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="firstName"
                name="name"
                label="Name"
                variant="outlined"
                value={userInfo.name}

                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value == "") {
                    setNameError(true)
                  } else {
                    setNameError(false)
                  }
                }}
                fullWidth
              />
              {nameError === true ? (
                <p style={{ color: "red", marginTop: "1px" }}>
                  Please enter your name
                </p>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                id="lastName"
                name="surname"
                label="Cognome"

                
                onChange={(e) => {
                  handleChange(e);
                  // if (e.target.value == "") {
                  //   props.seterrorSurname("Please enter your surname");
                  // } else {
                  //   props.seterrorSurname("");
                  // }
                }}
                value={userInfo.surname}
                fullWidth
              />{" "}
              {/* {props.errorSurname != "" ? (
              <p style={{ color: "red", marginTop: "1px" }}>
                {props.errorSurname}
              </p>
            ) : null} */}
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                variant="outlined"
                id="address2"
                type="number"
                // inputRef={phoneRef}
                name="phoneNumber"
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value == "") {
                    setPhoneNumberError(true)
                  } else {
                    setPhoneNumberError(false)
                  }
                }}
                label="Numero di cellulare"
                
                fullWidth
                value={userInfo.phoneNumber}
              />{" "}
              {phoneNumberError === true ? (
                <p style={{ color: "red", marginTop: "1px" }}>
                  Please enter phoneNumber
                </p>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                id="city"
                type="number"
                name="alternateNumber"
                onChange={(e) => {
                  handleChange(e);
                }}
                label="Altro numero di telefono"
                fullWidth
              value={userInfo.alternateNumber}
              
              />{" "}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="date"
                variant="outlined"
                required
                id="address1"
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value == "") {
                    setBirthDateError(true)
                  } else {
                    setBirthDateError(false)
                  }
                }}
                value={userInfo.birthDate}
                name="birthDate"
                // defaultValue={curDate}
                label="Data di nascita"
                
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />{" "}
              {birthDateError == true ? (
                <p style={{ color: "red", marginTop: "1px" }}>
                  Please select your birth date
                </p>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                // multiple
                id="tags-outlined"
                options={allSpecialization}
                getOptionLabel={(allSpecialization) => allSpecialization.label}
                filterSelectedOptions
                // ref={specialization}
                value={userInfo.specialization}
                name="specialization"
                onChange={(e, newValue) => {
                  setUserInfo({
                    ...userInfo,
                    specialization: newValue,
                  });
                  // if (userInfo.specialization.label == undefined) {
                  //   setSpecializationError(true)
                  // } else {
                  //   setSpecializationError(false)
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Specializzazioni"
                  // placeholder="Specializzazioni"
                  />
                )}
              />

              {/* {specializationError === true ? (
                <p style={{ color: "red", marginTop: "1px" }}>
                  Please select your specialization
                </p>
              ) : null} */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="tags-outlined"
                options={allCity}
                getOptionLabel={(allCity) => allCity.label}
                filterSelectedOptions
                // ref={specialization}
                value={userInfo.city}
                
                name="specialization"
                onChange={async (e, value) => {
                  setUserInfo({
                    ...userInfo,
                    city: value,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Città di riferimento attività"
                  />
                )}
              />

              {/* {props.errorSpecialization != "" ? (
            <p style={{ color: "red", marginTop: "1px" }}>
              {props.errorSpecialization}
            </p>
          ) : null} */}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="CAP"
                value={userInfo.CAP}
                onChange={(e) => {
                  handleChange(e);
                  // if (e.target.value == "") {
                  //   props.seterrorCAP("Please enter CAP");
                  // } else {
                  //   props.seterrorCAP("");
                  // }
                }}
                variant="outlined"
                id="cardNumber"
                label="CAP"
                fullWidth
                autoComplete="cc-number"
              />
              {/* {props.errorCAP != "" ? (
                <p style={{ color: "red", marginTop: "1px" }}>{props.errorCAP}</p>
              ) : null} */}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="address"
                variant="outlined"
                value={userInfo.address}
                onChange={(e) => {
                  handleChange(e);
                  // if (e.target.value == "") {
                  //   props.seterrorAddress("Please enter address");
                  // } else {
                  //   props.seterrorAddress("");
                  // }
                }}
                id="expDate"
                label="Indirizzo attività"
                fullWidth
                autoComplete="cc-exp"
              />
              {/* {props.errorAddress != "" ? (
                <p style={{ color: "red", marginTop: "1px" }}>
                  {props.errorAddress}
                </p>
              ) : null} */}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                value={userInfo.clinicName}
                name="clinicName"
                // onChange={handleChange}
                onChange={(e) => {
                  handleChange(e);
                  // if (e.target.value == "") {
                  //   setNameError(true)
                  // } else {
                  //   setNameError(false)
                  // }
                }}
                id="cvv"
                label="Nome Studio/Clinica"
                fullWidth
              // autoComplete="cc-csc"
              />
            </Grid>
            <Button
              variant="contained"
              onClick={handleUpdate}
              className={classes.button}
            >
              Salva
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              className={classes.button}
            >
              Elimina il tuo profilo
            </Button>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
