import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    textAlign: "center",
  },
  iconImg: {
    width: 120,
    height: 120,
    fontWeight: 100,
    color: "rgba(0, 0, 0, 0.7)",
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    zIndex: 99,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  specialization_select: {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
  },
  buttons: {
    paddingTop: "0px",
    display: "flex",
    justifyContent: "flex-end",
    // flexDirection: "column",
  },
  addNew: {
    marginLeft: theme.spacing(3),
  },
}));

export default useStyles;
