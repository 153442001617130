import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  formgroup: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    margin: "0 7px",
    
    borderRadius: "36px",
  },
  Backbutton: {
    margin: "0 7px",

    borderRadius: "36px",
    background: "#fff",
    color: "#2E9B92",
    border: "0.25px solid #2E9B92",
    "&:hover": {
      color: "#2E9B92",
      background: "white",
    },
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      margin: " 20px 0",
    },
  },
  grid: {
    marginBottom: "1.5rem",
    alignItems: "center",
  },
  gridItem: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
}));

export default useStyles;
