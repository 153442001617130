export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const ALL_SPECIALIZATIONS_SUCCESS = "ALL_SPECIALIZATIONS_SUCCESS";
export const ALL_CITIES_SUCCESS = "ALL_CITIES_SUCCESS";
export const USER_DETAILS_UPDATE = "USER_DETAILS_UPDATE";
export const LOGOUT = "LOGOUT";

export const VERIFY_ERROR = "VERIFY_ERROR";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

//for calendar
export const INSERT_SUCCESS = "INSERT_SUCCESS";
export const GET_CALENDAR_SUCCESS = "CALENDAR_SUCCESS";
export const GET_PATIENT = "GET_PATIENT";
export const GET_EVENT_RECORDS = "GET_EVENT_RECORDS";
export const GET_CLINIC = "GET_CLINIC";
