import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import ErrorReducer from "./ErrorReducer";
import CalendarReducer from "./CalendarReducer";

const reducers = combineReducers({
  user: UserReducer,
  calendar: CalendarReducer,
  error: ErrorReducer,
});
export default reducers;
