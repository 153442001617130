import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import useStyles from "./ResetPasswordStyles";
import axios from "axios";
import swal from "sweetalert";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ForgotPassword(props) {
  const classes = useStyles();
  const [confPassword, setConfPassword] = useState("");
  const [erroremail, seterroremail] = useState("");
  const [password, setPassword] = useState("");
  const [errorpassword, seterrorpassword] = useState("");
  const handleChange = (e) => {
    if (e.target.name !== "password") {
      setConfPassword(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };
  const handleSubmit = () => {
    if (password == "") {
      seterrorpassword("Please enter your password");
    } else if (password.length < 8) {
      seterrorpassword("Password must have atleast 8 characters");
    } else {
      seterrorpassword("");
    }
    if (confPassword == "") {
      seterroremail("Please re-enter your password");
    } else if (confPassword !== password) {
      seterroremail("Passwords do not match");
    } else {
      seterroremail("");
    }
    if (
      confPassword != "" &&
      confPassword == password &&
      password != "" &&
      password.length >= 8
    ) {
      let data = { token: window.location.href.split("=")[1],newpassword:password,confirm_password:confPassword }

      axios
      .post(`${process.env.REACT_APP_API_URL}/api/reset_password`,data)
      .then((res) => {
        console.log("res",res);
        if(res.data.success===1){
          swal("success", res.data.message, "success");
        }
        else{
          swal("Ooops!", res.data.message, "error");
        }
      })
      .catch((err) => {
        alert("some problem in featch paitent data");
      });
      props.history.push("/signin");
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Reset password
        </Typography>
        {/* <Typography
          variant="body"
          style={{ textAlign: "center", color: "black", marginTop: "1rem" }}
          gutterBottom
        >
          Reset your password
        </Typography> */}
        <div className={classes.form}>
          <TextField
            autoFocus
            variant="outlined"
            margin="normal"
            required
            value={password}
            fullWidth
            onChange={(e) => {
              handleChange(e);
              if (e.target.value == "") {
                seterrorpassword("Please enter your password");
              } else if (e.target.value.length < 8) {
                seterrorpassword("Password must have atleast 8 characters");
              } else {
                seterrorpassword("");
              }
            }}
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />{" "}
          {errorpassword != "" ? (
            <p style={{ color: "red", margin: 0 }}>{errorpassword}</p>
          ) : null}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={confPassword}
            id="email"
            type="password"
            onChange={(e) => {
              handleChange(e);

              if (e.target.value !== password) {
                seterroremail("Passwords do not match");
              } else {
                seterroremail("");
              }
            }}
            label="Confirm Password"
            name="confPassword"
          />
          {erroremail != "" ? (
            <p style={{ color: "red", margin: 0 }}>{erroremail}</p>
          ) : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/signup" variant="body2" className={classes.link}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
